import { combineReducers } from 'redux';

import * as authReducer from './reducers/auth';
import * as lifeStoryReducer from './reducers/lifeStory';
import * as funeralPlansReducer from './reducers/funeralPlans';
import * as accountManagerReducer from './reducers/accountManager';
import * as myLogsReducer from './reducers/myLogs';
import * as commonReducer from './reducers/common';
import * as settingsReducer from './reducers/settings';

import * as createWillCommonReducer from './reducers/createWill/common';
import * as createWillWelcomeReducer from './reducers/createWill/welcome';
import * as createWillPersonalReducer from './reducers/createWill/personal';
import * as createWillBeneficiariesReducer from './reducers/createWill/beneficiaries';
import * as createWillGuardiansReducer from './reducers/createWill/guardians';
import * as createWillAssetsReducer from './reducers/createWill/assets';
import * as createWillExecutorsReducer from './reducers/createWill/executors';
import * as createWillSummaryReducer from './reducers/createWill/summary';
import * as createWillFinalizeReducer from './reducers/createWill/finalize';

import * as manageWillManageReducer from './reducers/manageWill/manage';
import * as dashboardReducer from './reducers/dashboard';

import * as probateWillCommonReducer from './reducers/probateWill/common';
import * as probateWillWillInfoReducer from './reducers/probateWill/willInfo';
import * as probateWillFuneralPlanReducer from './reducers/probateWill/funeralPlan';
import * as probateWillLifeStoryReducer from './reducers/probateWill/lifeStory';
import * as probateWillMyLogsReducer from './reducers/probateWill/myLogs';
import * as probateWillAccountManagerReducer from './reducers/probateWill/accountManager';

import * as externalLogsReducer from './reducers/external/logs';

import { authUser } from '../libs/validation';

const appReducer = combineReducers({
  auth: authReducer.auth,
  lifeStory: lifeStoryReducer.lifeStory,
  funeralPlans: funeralPlansReducer.funeralPlans,
  accountManager: accountManagerReducer.accountManager,
  myLogs: myLogsReducer.myLogs,
  common: commonReducer.common,
  createWill: combineReducers({
    common: createWillCommonReducer.common,
    welcome: createWillWelcomeReducer.welcome,
    personal: createWillPersonalReducer.personal,
    beneficiaries: createWillBeneficiariesReducer.beneficiaries,
    guardians: createWillGuardiansReducer.guardians,
    assets: createWillAssetsReducer.assets,
    executors: createWillExecutorsReducer.executors,
    summary: createWillSummaryReducer.summary,
    finalize: createWillFinalizeReducer.finalize,
  }),
  manageWill: combineReducers({
    manage: manageWillManageReducer.manage,
  }),
  probateWill: combineReducers({
    common: probateWillCommonReducer.common,
    willInfo: probateWillWillInfoReducer.willInfo,
    funeralPlan: probateWillFuneralPlanReducer.funeralPlan,
    lifeStory: probateWillLifeStoryReducer.lifeStory,
    myLogs: probateWillMyLogsReducer.myLogs,
    accountManager: probateWillAccountManagerReducer.accountManager,
  }),
  external: combineReducers({
    logs: externalLogsReducer.logs,
  }),
  settings: settingsReducer.settings,
  dashboard: dashboardReducer.dashboard,
});

const rootReducer = (state, action) => {
  if (!authUser) {
    state = {
      auth: authReducer.initialState,
      lifeStory: lifeStoryReducer.initialState,
      funeralPlans: funeralPlansReducer.initialState,
      accountManager: accountManagerReducer.initialState,
      myLogs: myLogsReducer.initialState,
      common: commonReducer.initialState,
      createWill: {
        common: createWillCommonReducer.initialState,
        welcome: createWillWelcomeReducer.initialState,
        personal: createWillPersonalReducer.initialState,
        beneficiaries: createWillBeneficiariesReducer.initialState,
        guardians: createWillGuardiansReducer.initialState,
        assets: createWillAssetsReducer.initialState,
        executors: createWillExecutorsReducer.initialState,
        summary: createWillSummaryReducer.initialState,
        finalize: createWillFinalizeReducer.initialState,
      },
      manageWill: {
        manage: manageWillManageReducer.initialState,
      },
      probateWill: {
        common: probateWillCommonReducer.initialState,
        willInfo: probateWillWillInfoReducer.initialState,
        funeralPlan: probateWillFuneralPlanReducer.initialState,
        lifeStory: probateWillLifeStoryReducer.initialState,
        myLogs: probateWillMyLogsReducer.initialState,
        accountManager: probateWillAccountManagerReducer.initialState,
      },
      settings: settingsReducer.initialState,
      dashboard: dashboardReducer.initialState,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
