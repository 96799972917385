import React, { useState } from 'react';
import { Popover } from 'antd';

import 'antd/lib/popover/style/index.css';

let isMobile = false;
if (window.innerWidth < 768) {
  // Extra Small Device
  isMobile = true;
} else if (window.innerWidth < 991) {
  // Small Device
  isMobile = true;
} else if (window.innerWidth < 1199) {
  // Medium Device
  isMobile = false;
} else {
  // Large Device
  isMobile = false;
}

const PopOver = ({ children, content }) => {
  const [clicked, setclicked] = useState(false);
  const [hovered, sethovered] = useState(false);

  const handleHoverChange = (visible) => {
    setclicked(false);
    sethovered(visible);
  };

  const handleClickChange = (visible) => {
    setclicked(visible);
    sethovered(false);
  };

  return (
    <Popover
      style={{ width: 500 }}
      content={content}
      trigger="hover"
      visible={hovered}
      onVisibleChange={handleHoverChange}
    >
      {isMobile ? (
        <Popover
          content={content}
          trigger="click"
          visible={clicked}
          onVisibleChange={handleClickChange}
        >
          {children}
        </Popover>
      ) : (
        children
      )}
    </Popover>
  );
};
export { PopOver };
