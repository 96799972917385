module.exports = {
  PROBATE_GET_MY_LOGS_VIDEO_SUCCESS: 'PROBATE_GET_MY_LOGS_VIDEO_SUCCESS',
  PROBATE_GET_MY_LOGS_VIDEO_FAIL: 'PROBATE_GET_MY_LOGS_VIDEO_FAIL',

  PROBATE_GET_MY_LOGS_WRITTEN_SUCCESS: 'PROBATE_GET_MY_LOGS_WRITTEN_SUCCESS',
  PROBATE_GET_MY_LOGS_WRITTEN_FAIL: 'PROBATE_GET_MY_LOGS_WRITTEN_FAIL',

  PROBATE_RELEASE_VIDEO_LOG_SUCCESS: 'PROBATE_RELEASE_VIDEO_LOG_SUCCESS',
  PROBATE_RELEASE_VIDEO_LOG_FAIL: 'PROBATE_RELEASE_VIDEO_LOG_FAIL',

  PROBATE_RELEASE_WRITTEN_LOG_SUCCESS: 'PROBATE_RELEASE_WRITTEN_LOG_SUCCESS',
  PROBATE_RELEASE_WRITTEN_LOG_FAIL: 'PROBATE_RELEASE_WRITTEN_LOG_FAIL',
};
