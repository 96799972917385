import externalLogsTypes from '../../types/external/logs';
import commonTypes from '../../types/common';

export const initialState = {
  loading: false,
  error: '',
  type: null,
  log: null,
};

export const logs = (state = initialState, action) => {
  switch (action.type) {
    case externalLogsTypes.EXTERNAL_USER_GET_LOGS:
      return {
        ...state,
        loading: action.payload,
      };

    case externalLogsTypes.EXTERNAL_USER_GET_LOGS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        type: action.payload.type,
        log: action.payload.log,
      };

    case externalLogsTypes.EXTERNAL_USER_SET_LOGS:
      return {
        ...state,
        error: '',
        loading: false,
        type: action.payload.type,
        log: action.payload.log,
      };

    case externalLogsTypes.EXTERNAL_USER_GET_LOGS_FAIL:
      return {
        ...state,
        error: '',
        loading: false,
      };

    case externalLogsTypes.EXTERNAL_USER_GET_LOGS_RESET:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
