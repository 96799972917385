/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import isNull from 'lodash/isNull';
import {
  SettingOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import ProfileImage from '../ProfileImage/ProfileImage';
import { getProfileImageURL } from '../../../libs/imageUtils';

const Account = (props) => {
  const tempProfilePicBlob = useSelector(state => state.settings.accountProfilePic)
  const { logout, history, updateProfilePicInAccountComp } = props;

  const [user, setUser] = useState(null);
  const [signedCookies, setSignedCookies] = useState(null);
  const [profilePhotoURL, setProfilePhotoURL] = useState(null);

  useEffect(() => {
    updateProfilePicInAccountComp(null);
  }, []);

  useEffect(() => {
    if (signedCookies !== props.signedCookies) {
      setSignedCookies(props.signedCookies);
    }
  }, [props.signedCookies]);

  useEffect(() => {
    if (user !== props.user) {
      setUser(props.user);
    }
  }, [props.user]);

  useEffect(() => {
    if (!isNull(tempProfilePicBlob)) {
      setProfilePhotoURL(tempProfilePicBlob)
    } else if (user) {
        setProfilePhotoURL(getProfileImageURL(user));
    } else {
        setProfilePhotoURL(null);
    }
  }, [tempProfilePicBlob, user])

  const _logout = () => {
    logout();
    history.push('/login');
  };

  const _navigateToSettingsPage = () => {
    history.push('/settings');
  };

  const getProfilePhoto = () => {
    return (
      <ProfileImage icon={user && user.firstName[0]} size={40} src={profilePhotoURL} key={uuidv4()}/>
    );
  };

  return (
    <Dropdown className="account">
      <OverlayTrigger
				placement={'bottom'}
				overlay={
					<Tooltip>
						Account
        			</Tooltip>
				}
			>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {getProfilePhoto()}
      </Dropdown.Toggle>
      </OverlayTrigger>
      <Dropdown.Menu>
        <div className="d-flex profile">
          <div className="img">{getProfilePhoto()}</div>
          <span className="pt-12 pl-3">
            <h4>{user ? `${user.firstName} ${user.lastName}` : null}</h4>
            {/* <p>9th October 1984</p> */}
            {/* <p>See my profile</p> */}
          </span>
        </div>
        <Dropdown.Divider />
        <Dropdown.Item onClick={_navigateToSettingsPage}>
          <SettingOutlined /> Settings & Privacy
        </Dropdown.Item>
        <Dropdown.Item href="#/action-2" disabled>
          <QuestionCircleOutlined /> Help & Support
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={_logout}>
          <LogoutOutlined /> Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Account;
