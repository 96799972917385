import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo.svg';
import splashBG from '../../assets/images/splashBG.png';
import skipNext from '../../assets/images/icons/skipNext.svg';

// import './splash.scss';

const SplashScreen = (props) => {
  console.log(props);
  useEffect(() => {
    if (
      props.previousLocationPath == '/verify' ||
      props.previousLocationPath == '/registration'
    ) {
      props.history.push(props.location.href);
      window.onpopstate = function (event) {
        props.history.go(1);
      };

      window.location.reload();
    }
  }, []);

  return (
    <div className="splashWrapper">
      <div className="splashLeft">
        <img className="logo" src={Logo} alt="" />
        <div className="content-page">
          <div className="title">
            <div className="sub">Let's get</div>
            <div className="main">Started</div>
          </div>
          <div className="writeUp">
          Hi There 👋 ! Welcome to 'To My Family'. We love having you with us, Now you have the access to variety of tools to build your will from scratch, set up your beneficiaries and executors, manage your assets and much more!
            <br />
            <br /> Already got your own will signed? No problem, simply upload it and invite your executors to get will probation activities done.
            <br />
            <br /> In addition, do not forget to take the advantage of 'My logs', 'My life story' and 'Account manager' features to add more spice to your will.
          </div>
        </div>
        <div className="buttonSection">
          <Link to={'/create-will'}>
            <button className="btn btn-primary">
              Create your will now
            </button>
          </Link>
          <Link to={'/dashboard'} className="skip">
            Skip this step and create my will later{' '}
            <img className="skipIcon" src={skipNext} alt="" />
          </Link>
        </div>
      </div>
      <div className="splashRight">
        <img src={splashBG} alt="splash background" />
      </div>
    </div>
  );
};

export default SplashScreen;
