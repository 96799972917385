import summaryTypes from '../../types/createWill/summary';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  willDetails: {},
  attachments: [],
  isModalOpenAttachment: false,
  isModalOpenGenerateWill: false,
  isUploading: false,
  uploadingProgress: 0,
  partialySavedData: null,
  preSignedURLs: null,
  generatedWillHTML: null,
  uploadedSignedWills: [],
  uploadedSignedWillURL: null,
};

export const summary = (state = initialState, action) => {
  switch (action.type) {
    case summaryTypes.GET_SUMMARY_PAGE_UPLOAD_EXISTING_WILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        preSignedURLs: action.payload,
      };
    case summaryTypes.GET_SUMMARY_PAGE_UPLOAD_EXISTING_WILL_DOCUMENT_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_SUCCESS:
      return {
        ...state,
        uploadedSignedWills: action.payload,
      };
    case summaryTypes.GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_FAIL:
      return { ...state, error: action.payload };
    case summaryTypes.GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_PRESIGNED_URL_SUCCESS:
      return {
        ...state,
        uploadedSignedWillURL: action.payload,
      };
    case summaryTypes.GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_PRESIGNED_URL_FAIL:
      return { ...state, error: action.payload };
    case summaryTypes.CLEAR_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_DATA:
      return {
        ...state,
        uploadedSignedWills: [],
        uploadedSignedWillURL: null,
      };

    case summaryTypes.GET_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        generatedWillHTML: action.payload.generatedWillHTML,
      };
    case summaryTypes.GET_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.UPDATE_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        generatedWillHTML: action.payload.generatedWillHTML,
      };
    case summaryTypes.UPDATE_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.GET_SUMMARY_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        willDetails: action.payload,
      };
    case summaryTypes.GET_SUMMARY_PAGE_INFORMATION_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.UPDATE_SUMMARY_PAGE_OTHER_DETAILS_SUCCESS:
      return {
        ...state,
        willDetails: action.payload,
      };
    case summaryTypes.UPDATE_SUMMARY_PAGE_OTHER_DETAILS_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.GET_SUMMARY_PAGE_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: action.payload,
      };
    case summaryTypes.GET_SUMMARY_PAGE_ATTACHMENTS_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.ADD_WILL_ATTACHMENT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newAttachment = {
        ...action.payload,
      };
      return {
        ...state,
        attachments: [...state.attachments, newAttachment],
        partialySavedData: newAttachment,
        error: '',
      };
    case summaryTypes.ADD_WILL_ATTACHMENT_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.DELETE_WILL_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachments: state.attachments.filter(
          (obj) => action.payload !== obj._id
        ),
        error: '',
      };
    case summaryTypes.DELETE_WILL_ATTACHMENT_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.WILL_DOCUMENTS_DOWNLOAD_SUCCESS:
      return { ...state };
    case summaryTypes.WILL_DOCUMENTS_DOWNLOAD_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.WILL_ATTACHMENT_DOWNLOAD_SUCCESS:
      return { ...state };
    case summaryTypes.WILL_ATTACHMENT_DOWNLOAD_FAIL:
      return { ...state, error: action.payload };

    case summaryTypes.WILL_ATTACHMENT_UPLOADING_START:
      return { ...state, isUploading: true };
    case summaryTypes.WILL_ATTACHMENT_UPLOADING_PROGRESS:
      return { ...state, uploadingProgress: action.payload };
    case summaryTypes.WILL_ATTACHMENT_UPLOADING_SUCCESS:
      return { ...state, isUploading: false, partialySavedData: null };
    case summaryTypes.WILL_ATTACHMENT_UPLOADING_FAIL:
      return { ...state, isUploading: false };
    case summaryTypes.WILL_ATTACHMENT_UPLOADING_CANCEL_BY_USER:
      return { ...state, isUploading: false, partialySavedData: null };

    case summaryTypes.WILL_ATTACHMENT_OPEN_MODAL:
      return { ...state, isModalOpenAttachment: true };
    case summaryTypes.WILL_ATTACHMENT_CLOSE_MODAL:
      return { ...state, isModalOpenAttachment: false };

    case summaryTypes.WILL_GENERATE_OPEN_MODAL:
      return { ...state, isModalOpenGenerateWill: true };
    case summaryTypes.WILL_GENERATE_CLOSE_MODAL:
      return { ...state, isModalOpenGenerateWill: false };

    case summaryTypes.RESET_WILL_SUMMARY_PAGE:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
