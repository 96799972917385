import executorTypes from '../../types/createWill/executors';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  editItem: null,
  executorsList: [],
  originalExecutorsList: [],
  executorRemarks: '',
  originalExecutorRemarks: '',
};

export const executors = (state = initialState, action) => {
  switch (action.type) {
    case executorTypes.GET_EXECUTORS_PAGE_SUCCESS:
      return {
        ...state,
        executorsList: action.payload.executors,
        originalExecutorsList: action.payload.executors,
        executorRemarks: action.payload.executorRemarks,
        originalExecutorRemarks: action.payload.executorRemarks,
      };
    case executorTypes.GET_EXECUTORS_PAGE_FAIL:
      return { ...state, error: action.payload };

    case executorTypes.UPDATE_EXECUTORS_PAGE_SUCCESS:
      return {
        ...state,
        executorsList: action.payload.executors,
        executorRemarks: action.payload.executorRemarks,
      };
    case executorTypes.UPDATE_EXECUTORS_PAGE_FAIL:
      return { ...state, error: action.payload };

    case executorTypes.SET_EXECUTORS_EDIT_ITEM:
      return { ...state, editItem: action.payload };

    case executorTypes.SET_EXECUTORS_DATA:
      return { ...state, executorsList: action.payload };

    case executorTypes.SET_EXECUTORS_OTHER_DATA:
      return { ...state, executorRemarks: action.payload.executorRemarks };

    case executorTypes.RESET_WILL_EXECUTORS_PAGE:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
