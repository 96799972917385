module.exports = {
  FUNERAL_PLANS_SUCCESS: 'FUNERAL_PLANS_SUCCESS',

  GET_FUNERAL_PLANS: 'GET_FUNERAL_PLANS',
  GET_FUNERAL_PLANS_FAIL: 'GET_FUNERAL_PLANS_FAIL',

  GET_FUNERAL_PLANS_VIDEO_LOGS_SUCCESS: 'GET_FUNERAL_PLANS_VIDEO_LOGS_SUCCESS',
  GET_FUNERAL_PLANS_VIDEO_LOGS_FAIL: 'GET_FUNERAL_PLANS_VIDEO_LOGS_FAIL',

  ADD_FUNERAL_PLANS: 'ADD_FUNERAL_PLANS',
  ADD_FUNERAL_PLANS_FAIL: 'ADD_FUNERAL_PLANS_FAIL',

  UPDATE_FUNERAL_PLANS: 'UPDATE_FUNERAL_PLANS',
  UPDATE_FUNERAL_PLANS_FAIL: 'UPDATE_FUNERAL_PLANS_FAIL',

  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
};
