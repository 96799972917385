import probateWillCommonTypes from '../../types/probateWill/common';
import probateWillMyLogsTypes from '../../types/probateWill/myLogs';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  videoLogs: [],
  writtenLogs: [],
};

export const myLogs = (state = initialState, action) => {
  switch (action.type) {
    case probateWillMyLogsTypes.PROBATE_GET_MY_LOGS_VIDEO_SUCCESS:
      return {
        ...state,
        videoLogs: action.payload,
      };
    case probateWillMyLogsTypes.PROBATE_GET_MY_LOGS_VIDEO_FAIL:
      return { ...state, error: action.payload, videoLogs: [] };

    case probateWillMyLogsTypes.PROBATE_GET_MY_LOGS_WRITTEN_SUCCESS:
      return {
        ...state,
        writtenLogs: action.payload,
      };
    case probateWillMyLogsTypes.PROBATE_GET_MY_LOGS_WRITTEN_FAIL:
      return { ...state, error: action.payload, writtenLogs: [] };

    case probateWillMyLogsTypes.PROBATE_RELEASE_VIDEO_LOG_SUCCESS:
      return {
        ...state,
        videoLogs: action.payload,
      };
    case probateWillMyLogsTypes.PROBATE_RELEASE_VIDEO_LOG_FAIL:
      return { ...state, error: action.payload };

    case probateWillMyLogsTypes.PROBATE_RELEASE_WRITTEN_LOG_SUCCESS:
      return {
        ...state,
        writtenLogs: action.payload,
      };
    case probateWillMyLogsTypes.PROBATE_RELEASE_WRITTEN_LOG_FAIL:
      return { ...state, error: action.payload };

    case probateWillCommonTypes.RESET_PROBATE_WILL:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
