import finalizeTypes from '../../types/createWill/finalize';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  holographicWillDownloaded: false,
  holographicWillPrinted: false,
  stage: null,
  userSelectedFlow: null,
  attachments: [],
  isUploading: false,
  uploadingProgress: 0,
  isModalOpen: false,
  generatedWillHTML: null,
};

export const finalize = (state = initialState, action) => {
  switch (action.type) {
    case finalizeTypes.GET_WILL_FINALIZE_PAGE_COMMON_DATA_SUCCESS:
      return {
        ...state,
        holographicWillDownloaded: action.payload.holographicWillDownloaded,
        holographicWillPrinted: action.payload.holographicWillPrinted,
        stage: action.payload.stage,
        userSelectedFlow: action.payload.userSelectedFlow,
      };
    case finalizeTypes.GET_WILL_FINALIZE_PAGE_COMMON_DATA_FAIL:
      return { ...state, error: action.payload };


    case finalizeTypes.UPDATE_WILL_FINALIZE_PAGE_COMMON_DATA_SUCCESS:
      return {
        ...state,
        holographicWillDownloaded: action.payload.holographicWillDownloaded,
        holographicWillPrinted: action.payload.holographicWillPrinted,
        stage: action.payload.stage,
        userSelectedFlow: action.payload.userSelectedFlow,
      };
    case finalizeTypes.UPDATE_WILL_FINALIZE_PAGE_COMMON_DATA_FAIL:
      return { ...state, error: action.payload };


    case finalizeTypes.GET_UPLOADED_SIGNED_WILL_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case finalizeTypes.GET_UPLOADED_SIGNED_WILL_ATTACHMENTS_FAIL:
      return { ...state, error: action.payload };

    case finalizeTypes.ADD_SIGNED_WILL_ATTACHMENT_SUCCESS:
      return { ...state };
    case finalizeTypes.ADD_SIGNED_WILL_ATTACHMENT_FAIL:
      return { ...state, error: action.payload };

    case finalizeTypes.SIGNED_WILL_ATTACHMENT_UPLOADING_START:
      return { ...state, isUploading: true };
    case finalizeTypes.SIGNED_WILL_ATTACHMENT_UPLOADING_PROGRESS:
      return { ...state, uploadingProgress: action.payload };
    case finalizeTypes.UPLOAD_SIGNED_WILL_ATTACHMENT_SUCCESS:
      return { ...state, isUploading: false, attachments: action.payload };
    case finalizeTypes.UPLOAD_SIGNED_WILL_ATTACHMENT_FAIL:
      return { ...state, isUploading: false };
    case finalizeTypes.SIGNED_WILL_ATTACHMENT_UPLOADING_CANCEL_BY_USER:
      return { ...state, isUploading: false };

    case finalizeTypes.DELETE_SIGNED_WILL_ATTACHMENT_SUCCESS:
      return { ...state };
    case finalizeTypes.DELETE_SIGNED_WILL_ATTACHMENT_FAIL:
      return { ...state, error: action.payload };

    case finalizeTypes.SEND_LAWYER_INVITATIONS_FROM_FINALIZE_WILL_SUCCESS:
      return { ...state };
    case finalizeTypes.SEND_LAWYER_INVITATIONS_FROM_FINALIZE_WILL_FAIL:
      return { ...state, error: action.payload };

    case finalizeTypes.NOTIFY_EXECUTORS_FROM_FINALIZE_WILL_SUCCESS:
      return { ...state, stage: 'finalized' };
    case finalizeTypes.NOTIFY_EXECUTORS_FROM_FINALIZE_WILL_FAIL:
      return { ...state, error: action.payload };

    case finalizeTypes.GET_FINALIZE_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        generatedWillHTML: action.payload.generatedWillHTML,
      };
    case finalizeTypes.GET_FINALIZE_PAGE_GENERATED_WILL_DOCUMENT_FAIL:
      return { ...state, error: action.payload };

    case finalizeTypes.FINALIZE_WILL_OPEN_MODAL:
      return { ...state, isModalOpen: true };
    case finalizeTypes.FINALIZE_WILL_CLOSE_MODAL:
      return { ...state, isModalOpen: false };

    case finalizeTypes.RESET_WILL_FINALIZE_PAGE:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
