module.exports = {
	WELCOME_PAGE_UPDATE_SUCCESS: 'WELCOME_PAGE_UPDATE_SUCCESS',

	GET_WELCOME_PAGE_SUCCESS: 'GET_WELCOME_PAGE_SUCCESS',
	GET_WELCOME_PAGE_FAIL: 'GET_WELCOME_PAGE_FAIL',

	UPDATE_WELCOME_PAGE_SUCCESS: 'UPDATE_WELCOME_PAGE_SUCCESS',
	UPDATE_WELCOME_PAGE_FAIL: 'UPDATE_WELCOME_PAGE_FAIL',

	WILL_DOCUMENT_UPLOADING_START: 'WILL_DOCUMENT_UPLOADING_START',
	WILL_DOCUMENT_UPLOADING_PROGRESS: 'WILL_DOCUMENT_UPLOADING_PROGRESS',
	WILL_DOCUMENT_UPLOADING_SUCCESS: 'WILL_DOCUMENT_UPLOADING_SUCCESS',
	WILL_DOCUMENT_UPLOADING_FAIL: 'WILL_DOCUMENT_UPLOADING_FAIL',
	WILL_DOCUMENT_UPLOADING_CANCEL_BY_USER: 'WILL_DOCUMENT_UPLOADING_CANCEL_BY_USER',

	QUICK_UPDATE_WELCOME_PAGE_DATA: 'QUICK_UPDATE_WELCOME_PAGE_DATA',

	RESET_WILL_WELCOMEPAGE: 'RESET_WILL_WELCOMEPAGE',

	UPDATE_WILL_TYPE_AND_CATEGORY: 'UPDATE_WILL_TYPE_AND_CATEGORY',
};
