module.exports = {
  ASSETS_PAGE_UPDATE_SUCCESS: 'ASSETS_PAGE_UPDATE_SUCCESS',

  GET_ASSETS_PAGE_SUCCESS: 'GET_ASSETS_PAGE_SUCCESS',
  GET_ASSETS_PAGE_FAIL: 'GET_ASSETS_PAGE_FAIL',

  UPDATE_ASSETS_PAGE_SUCCESS: 'UPDATE_ASSETS_PAGE_SUCCESS',
  UPDATE_ASSETS_PAGE_FAIL: 'UPDATE_ASSETS_PAGE_FAIL',

  RESET_WILL_ASSETS_PAGE: 'RESET_WILL_ASSETS_PAGE',
};
