import { createSelector } from 'reselect';

const commonDomain = ({ common }) => common;

const responceMessage = createSelector([commonDomain], (commonState) => {
  const alert = {
    show: commonState.show,
    message: commonState.message,
    type: commonState.type,
  };

  return alert;
});
const sessionExpire = createSelector([commonDomain], (commonState) => {
  return commonState.sessionExpire;
});

export { responceMessage, sessionExpire };
