import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from '../../../config/firebase';
import isNil from 'lodash/isNil';
import { Dropdown, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import moment from 'moment';
import { BellOutlined } from '@ant-design/icons';
import { getFormattedDateString } from '../../../libs/datetimeUtils';
import ProfileImage from '../ProfileImage/ProfileImage';
import { createProfileImageURL } from '../../../libs/imageUtils';

const Notifications = ({ userId }) => {
	const { firebaseToken } = useSelector((state) => state.auth);
	const [isDropDownOpen, setDropDownOpen] = useState(false);
	const [allNotifications, setAllNotifications] = useState([]);
	const [paginatedNotifyList, setPaginatedNotifyList] = useState([]);
	const [unreadNotifications, setUnreadNotifications] = useState([]);
	const [unreadNotifyCount, setUnreadNotifyCount] = useState(0);
	const [pagination, setPagination] = useState(10);

	useEffect(() => {
		fetchAllNotifications();
		fetchPaginatedNotifs(10);
	}, []);

	useEffect(() => {
		if (!isNil(firebaseToken)) {
			firebaseAuthentication(firebaseToken);
		}
	}, [firebaseToken]);

	const firebaseAuthentication = (token) => {
		firebase
			.auth()
			.setPersistence(firebase.auth.Auth.Persistence.SESSION)
			.then(() => {
				return firebase.auth().signInWithCustomToken(token);
			})
			.catch((error) => {
				console.log('error : ', error);
			});
	};

	useEffect(() => {
		if (!isDropDownOpen) {
			return;
		}
		for (let index = 0; index < unreadNotifications.length; index++) {
			updateBellIconNotifyCountStatus(unreadNotifications[index]);
		}
	}, [isDropDownOpen]);

	const fetchPaginatedNotifs = (pagination) => {
		const notificationsRef = firebase.database().ref(`tomyfamily/notifications/${userId}`);
		notificationsRef
			.orderByKey()
			.limitToLast(pagination)
			.on('value', (snapshot) => {
				const data = snapshot.val();
				if (data) {
					const formattedArr = Object.keys(data).map((key) => ({ ...data[key], id: key }));
					setPaginatedNotifyList(formattedArr.reverse());
				}
			});
	};

	// fetch all notifications
	const fetchAllNotifications = () => {
		const notificationsRef = firebase.database().ref(`tomyfamily/notifications/${userId}`);
		notificationsRef.on('value', (snapshot) => {
			const data = snapshot.val();
			if (data) {
				const formattedArr = Object.keys(data).map((key) => ({ ...data[key], id: key }));
				const unreadNotifs = formattedArr.filter(
					(notification) =>
						!isNil(notification.browserNotificationSeenStatus) &&
						!notification.browserNotificationSeenStatus
				);
				setAllNotifications(formattedArr);
				setUnreadNotifications(unreadNotifs);
				setUnreadNotifyCount(unreadNotifs.length);
			}
		});
	};

	const updateNotificationSeenStatus = (notification) => {
		if (!notification.isSeen) {
			const notificationRef = firebase
				.database()
				.ref(`tomyfamily/notifications/${userId}/${notification.id}`);
			notificationRef.update({
				isSeen: true,
			});
		}
	};

	const updateBellIconNotifyCountStatus = (notification) => {
		if (
			!isNil(notification.browserNotificationSeenStatus) &&
			!notification.browserNotificationSeenStatus
		) {
			firebase.database().ref(`tomyfamily/notifications/${userId}/${notification.id}`).update({
				browserNotificationSeenStatus: true,
			});
		}
	};

	const scrollHandler = (event) => {
		if (event.target.scrollTop >= event.target.scrollHeight - event.target.offsetHeight) {
			if (paginatedNotifyList.length < allNotifications.length) {
				setPagination(pagination + 10);
				loadNextPageNotifs(pagination + 10);
			}
		}
	};

	const loadNextPageNotifs = (pagination) => {
		const notificationsRef = firebase.database().ref(`tomyfamily/notifications/${userId}`);
		notificationsRef
			.orderByKey()
			.limitToLast(pagination)
			.once('value')
			.then((snapshot) => {
				const data = snapshot.val();
				if (data) {
					const formattedArr = Object.keys(data).map((key) => ({ ...data[key], id: key }));
					setPaginatedNotifyList(formattedArr.reverse());
				}
			});
	};

	const markAllNotifsRead = () => {
		setUnreadNotifications(0);
		allNotifications.map((notifs) => {
			if (!notifs.isSeen) {
				updateNotificationSeenStatus(notifs);
			}
		});
	};

	const handleSingleNotificationClick = (notification, event) => {
		updateNotificationSeenStatus(notification);
	};

	return (
		<Dropdown
			className="notification"
			onClick={() => {
				setUnreadNotifyCount(0);
			}}
			onToggle={(isOpen, e, meta) => {
				if (meta.source === 'select') {
					return;
				}
				setDropDownOpen(!isDropDownOpen);
			}}
			show={isDropDownOpen}
		>
			<OverlayTrigger placement={'bottom'} overlay={<Tooltip>Notifications</Tooltip>}>
				<Dropdown.Toggle variant="success" id="dropdown-basic" menuAlign="right">
					{unreadNotifyCount > 0 ? (
						<>
							<BellOutlined />
							{unreadNotifyCount > 9 ? (
								<div className="new-count more">9+</div>
							) : (
								<div className="new-count">{unreadNotifyCount}</div>
							)}
						</>
					) : (
						<BellOutlined />
					)}
				</Dropdown.Toggle>
			</OverlayTrigger>
			<Dropdown.Menu onScroll={(e) => scrollHandler(e)}>
				<div className="font-medium ml-20 mr-20 mb-15  mt-15 font-medium">Notifications</div>
				<div className="dropdown-divider" role="separator"></div>
				<div className="notification-list">
					{paginatedNotifyList &&
						paginatedNotifyList.length > 0 &&
						paginatedNotifyList.map((notification) => {
							return (
								<div
									key={notification.id}
									className={notification.isSeen ? 'read item' : 'unread item'}
								>
									<Dropdown.Item
										eventKey={notification.id}
										onSelect={(ekey, evt) => {
											evt.stopPropagation();
											evt.preventDefault();
											handleSingleNotificationClick(notification);
										}}
									>
										<div className="block">
											<div className="image mr-3">
												<ProfileImage
													icon={notification.senderName.charAt(0)}
													src={createProfileImageURL(notification.senderId)}
													type="notificationImage"
													className="joint"
												/>
											</div>
											<div className="notifications-info">
												<div className="d-flex">
													<div className="font-medium truncate title">
														{notification.senderName}
													</div>
													<div className="truncate ml-auto text-xs date">
														{moment(notification.timeStamp).format('ll LT')}
														{/* {moment(notification.timeStamp).startOf('day').fromNow()} */}
													</div>
												</div>
												<p className="text-gray ">{notification.notificationText}</p>
											</div>
										</div>
									</Dropdown.Item>
								</div>
							);
						})}
				</div>
				<div className="notification-footer">
					{allNotifications.length !== 0 ? (
						<Button onClick={() => markAllNotifsRead()}>Mark all as read</Button>
					) : (
						<button type="button" className="btn btn-primary">
							No notifications yet
						</button>
					)}
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Notifications;
