import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';

const CustomProgressBar = (props) => {
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    if (props.currentProgress !== currentProgress) {
      setCurrentProgress(props.currentProgress);
    }
  }, [props.currentProgress]);

  const getProgressBar = () => {
    return currentProgress ? (
      <ProgressBar
        animated
        variant="info"
        now={currentProgress}
        label={`${currentProgress}%`}
      />
    ) : null;
  };

  return <div>{getProgressBar()}</div>;
};

export { CustomProgressBar };
