module.exports = {
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  CLEAR_STORAGE: 'CLEAR_STORAGE',
  SESSION_EXPIRE: 'SESSION_EXPIRE',
  SHOW: 'SHOW',
  HIDE: 'HIDE',

  RESPONSE_ALERT: {
    ERROR: 1,
    SUCCESS: 2,
    INFO: 3,
    WARNING: 4,
  },

  CONFIRM_ALERT: {
    INFO: 1,
    WARNING: 2,
  },

  IMAGE_SIZE: {
    MAX_SIZE: 2097152, // 2MB
  },

  USERACTIONS: {
    ADD: 1,
    UPDATE: 2,
    DELETE: 3,
  },
};
