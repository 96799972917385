import React from 'react';
import classNames from 'classnames';

import searchIcon from '../../../assets/images/icons/search-not-found.png';

const NoDataFound = ({
  search = false,
  icon = null,
  title = '',
  content = '',
  className,
  style = null,
}) => {
  const image = search ? searchIcon : icon;
  const heading = search ? 'No results found' : title;
  const text = search
    ? "We couldn't find any recodes based on your search."
    : content;
  let emptyClass = classNames('empty', className);

  return (
    <div className={emptyClass} style={style}>
      <div>
        <img src={image} />
        <h3>{heading}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export { NoDataFound };
