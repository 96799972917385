import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { PDFReader, MobilePDFReader } from 'reactjs-pdf-view';
import {
  DownloadOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';

const PDFViewer = (props) => {
  const { url, downloadHandler, isReadonly } = props;

  let scrollToTopRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [manualEnteredPageNumber, setManualEnteredPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const manualEnteredPageNumberOnchange = (e) => {
    let value = e.target.value;
    if (value) {
      value = Number(value);
      setManualEnteredPageNumber(value);
    } else {
      setManualEnteredPageNumber('');
    }
  };

  const triggerPageNavigation = (e) => {
    if (e.keyCode === 13) {
      goSpecificPage(manualEnteredPageNumber);
    }
  };
  const goSpecificPage = (value) => {
    if (totalPages >= value) {
      setCurrentPage(value);
      scrollToTop();
    }
  };
  const goBackPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setManualEnteredPageNumber(currentPage - 1);
      scrollToTop();
    }
  };
  const goNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setManualEnteredPageNumber(currentPage + 1);
      scrollToTop();
    }
  };

  const scrollToTop = () => {
    if (scrollToTopRef) {
      scrollToTopRef.scrollIntoView();
    }
  };

  return (
    <div className="pdf-view">
      <div ref={(node) => (scrollToTopRef = node)} />
      <PDFReader
        page={currentPage}
        // scale={1}
        // width={700}
        showAllPage={false}
        // style={{ overflow: 'scroll', height: 600 }}
        onDocumentComplete={(totalPage) => {
          setTotalPages(totalPage);
          setIsLoading(false);
        }}
        // getPageNumber={(currentPage) => {}}
        url={url}
      />
      {isLoading ? (
        <div className="pdf-view-loading">Loading...</div>
      ) : (
        <div className="pdf-action-btn pl-4 pr-4">
          <Button
              className="btn btn-primary-soft ml-a"
            onClick={goBackPage}
            disabled={currentPage === 1 ? true : false}
          >
            <ArrowLeftOutlined />
          </Button>
          <input
            type="number"
            value={manualEnteredPageNumber}
            onChange={manualEnteredPageNumberOnchange}
            onKeyUp={triggerPageNavigation}
          />
          <span className="pl-2 pr-3">{`/${totalPages}`}</span>
          <Button
              className="btn btn-primary-soft ml-a"
            onClick={goNextPage}
            disabled={currentPage === totalPages ? true : false}
          >
            <ArrowRightOutlined />
          </Button>
          <Button
              className="btn btn-primary-soft ml-a float-right"
            onClick={downloadHandler}
            disabled={totalPages === 0 ? true : false}
            style={isReadonly ? { visibility: 'hidden' } : null}
          >
            <DownloadOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};

PDFViewer.propTypes = {
  // isModalOpen: PropTypes.bool,
};

export { PDFViewer };
