module.exports = {
  PROBATE_GET_UPLOAD_EXISTING_WILL_DOCUMENT_SUCCESS:
    'PROBATE_GET_UPLOAD_EXISTING_WILL_DOCUMENT_SUCCESS',
  PROBATE_GET_UPLOAD_EXISTING_WILL_DOCUMENT_FAIL:
    'PROBATE_GET_UPLOAD_EXISTING_WILL_DOCUMENT_FAIL',

  PROBATE_GET_UPLOADED_SIGNED_WILL_SUCCESS:
    'PROBATE_GET_UPLOADED_SIGNED_WILL_SUCCESS',
  PROBATE_GET_UPLOADED_SIGNED_WILL_FAIL:
    'PROBATE_GET_UPLOADED_SIGNED_WILL_FAIL',
  PROBATE_GET_UPLOADED_SIGNED_WILL_PRESIGNED_URL_SUCCESS:
    'PROBATE_GET_UPLOADED_SIGNED_WILL_PRESIGNED_URL_SUCCESS',
  PROBATE_GET_UPLOADED_SIGNED_WILL_PRESIGNED_URL_FAIL:
    'PROBATE_GET_UPLOADED_SIGNED_WILL_PRESIGNED_URL_FAIL',

  PROBATE_GET_GENERATED_WILL_DOCUMENT_SUCCESS:
    'PROBATE_GET_GENERATED_WILL_DOCUMENT_SUCCESS',
  PROBATE_GET_GENERATED_WILL_DOCUMENT_FAIL:
    'PROBATE_GET_GENERATED_WILL_DOCUMENT_FAIL',

  RESET_PROBATE_WILL_WILL_PREVIEW_DATA: 'RESET_PROBATE_WILL_WILL_PREVIEW_DATA',
};
