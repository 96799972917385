import myLogsTypes from '../types/myLogsStates';
import commonTypes from '../types/common';

export const initialState = {
  newVideo: {
    _id: '',
    publishTime: 0,
    title: '',
    remark: '',
    receivers: [],
    videoType: 0,
    videoUrl: '',
  },
  videos: [],
  error: '',
  load: false,
  writeLogsList: [],
  newWriteLog: {
    _id: '',
    title: '',
    remark: '',
    LogText: '',
    receivers: [],
    writtenLogType: 0,
    publishTime: 0,
    createdAt: '',
  },
  isModalOpenVideoLog: false,
  isModalOpenWriteLog: false,
  isUploading: false,
  uploadingProgress: 0,
  partialySavedData: null,
  videoLogsAllocation: {
    allocatedQuota: 0,
    usedQuota: 0,
  },
};

export const myLogs = (state = initialState, action) => {
  switch (action.type) {
    // video logs specefic

    case myLogsTypes.GET_VIDEO_LOGS:
      return {
        ...state,
        load: true,
        error: '',
      };
    case myLogsTypes.GET_VIDEO_LOGS_SUCCESS:
      return {
        ...state,
        load: false,
        videos: action.payload,
        error: '',
      };
    case myLogsTypes.GET_VIDEO_LOGS_FAIL:
      return { ...state, error: action.payload };

    case myLogsTypes.ADD_VIDEO_LOGS:
      return {
        ...state,
        load: true,
        error: '',
      };
    case myLogsTypes.ADD_VIDEO_LOGS_SUCCESS:
      const addedVideo = {
        _id: action.payload._id,
        publishTime: action.payload.publishTime,
        title: action.payload.title,
        remark: action.payload.remark,
        receivers: action.payload.receivers,
        videoType: action.payload.videoType,
        videoUrl: action.payload.videoUrl,
        key: action.payload.key,
      };
      return {
        ...state,
        load: false,
        newVideo: addedVideo,
        videos: [...state.videos, addedVideo],
        partialySavedData: addedVideo,
        error: '',
      };
    case myLogsTypes.ADD_VIDEO_LOGS_FAIL:
      return { ...state, error: action.payload };

    case myLogsTypes.UPDATE_VIDEO_LOGS:
      return {
        ...state,
        load: true,
        error: '',
      };
    case myLogsTypes.UPDATE_VIDEO_LOGS_SUCCESS:
      return {
        ...state,
        load: false,
        videos: state.videos.map((obj) =>
          action.payload._id === obj._id ? action.payload : obj
        ),
        error: '',
      };
    case myLogsTypes.UPDATE_VIDEO_LOGS_FAIL:
      return { ...state, error: action.payload };

    case myLogsTypes.DELETE_VIDEO_LOGS:
      return {
        ...state,
        load: true,
        error: '',
      };
    case myLogsTypes.DELETE_VIDEO_LOGS_SUCCESS:
      return {
        ...state,
        load: false,
        videos: state.videos.filter((obj) => action.payload !== obj._id),
        error: '',
        partialySavedData: null,
      };
    case myLogsTypes.DELETE_VIDEO_LOGS_FAIL:
      return { ...state, error: action.payload };

    case myLogsTypes.UPLOADING_START:
      return { ...state, isUploading: true };
    case myLogsTypes.UPLOADING_PROGRESS:
      return { ...state, uploadingProgress: action.payload };
    case myLogsTypes.UPLOADING_SUCCESS:
      return { ...state, isUploading: false, partialySavedData: null };
    case myLogsTypes.UPLOADING_FAIL:
      return { ...state, isUploading: false };
    case myLogsTypes.UPLOADING_CANCEL_BY_USER:
      return { ...state, isUploading: false };

    // write logs specefic

    case myLogsTypes.GET_WRITE_LOGS:
      return {
        ...state,
        load: true,
        error: '',
      };
    case myLogsTypes.GET_WRITE_LOGS_SUCCESS:
      return {
        ...state,
        load: false,
        writeLogsList: action.payload.writtenLogs
          ? action.payload.writtenLogs
          : [],
        error: '',
      };
    case myLogsTypes.GET_WRITE_LOGS_FAIL:
      return { ...state, error: action.payload };

    case myLogsTypes.CREATE_WRITE_LOGS:
      return {
        ...state,
        load: true,
        error: '',
      };
    case myLogsTypes.CREATE_WRITE_LOGS_FAIL:
      return {
        ...state,
        error: '',
      };
    case myLogsTypes.CREATE_WRITE_LOGS_SUCCESS:
      state.newWriteLog = {
        _id: action.payload._id,
        title: action.payload.title,
        remark: action.payload.remark,
        LogText: action.payload.LogText,
        receivers: action.payload.receivers,
        writtenLogType: action.payload.writtenLogType,
        publishTime: action.payload.publishTime,
        createdAt: action.payload.createdAt,
      };
      return {
        ...state,
        load: true,
        writeLogsList: [...state.writeLogsList, state.newWriteLog],
        error: '',
      };

    case myLogsTypes.UPDATE_WRITE_LOGS_SUCCESS:
      let newArray = state.writeLogsList.filter(
        (logs) => logs._id !== action.payload._id
      );
      state.updatedWriteLog = {
        _id: action.payload._id,
        title: action.payload.title,
        remark: action.payload.remark,
        LogText: action.payload.LogText,
        receivers: action.payload.receivers,
        writtenLogType: action.payload.writtenLogType,
        publishTime: action.payload.publishTime,
        createdAt: action.payload.createdAt,
      };
      return {
        ...state,
        load: true,
        writeLogsList: [...newArray, state.updatedWriteLog].sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        ),
        error: '',
      };

    case myLogsTypes.DELETE_WRITE_LOGS_SUCCESS:
      return {
        ...state,
        load: true,
        writeLogsList: state.writeLogsList.filter(
          (logs) => logs._id !== action.payload._id
        ),
        error: '',
      };

    case myLogsTypes.GET_VIDEO_LOGS_QUOTA:
      return { ...state, load: true };
    case myLogsTypes.GET_VIDEO_LOGS_QUOTA_SUCCESS:
      return {
        ...state,
        load: false,
        videoLogsAllocation: {
          allocatedQuota: action.payload.allocatedQuota,
          usedQuota: action.payload.usedQuota,
        },
      };
    case myLogsTypes.GET_VIDEO_LOGS_QUOTA_FAIL:
      return { ...state, load: false, error: action.payload };

    case myLogsTypes.OPEN_MODAL_VIDEO_LOGS:
      return { ...state, isModalOpenVideoLog: true };
    case myLogsTypes.CLOSE_MODAL_VIDEO_LOGS:
      return { ...state, isModalOpenVideoLog: false, partialySavedData: null };

    case myLogsTypes.OPEN_MODAL_WRITE_LOGS:
      return { ...state, isModalOpenWriteLog: true };
    case myLogsTypes.CLOSE_MODAL_WRITE_LOGS:
      return { ...state, isModalOpenWriteLog: false };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
