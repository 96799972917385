import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { MenuUnfoldOutlined, MenuOutlined } from '@ant-design/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Logo from '../../assets/images/logo.svg';
import Notifications from '../../components/common/Header/Notifications';
import Account from '../../components/common/Header/Account';
import Profile from '../../components/common/Header/Profile';

const Header = (props) => {
	const { _id } = useSelector((state) => state.auth.user);
	return (
		<header>
			<div className="topBar">
				<nav className="navbar justify-content-between full-with w-100">
					<div className="d-flex">
						<div className="menu-side">
							<OverlayTrigger placement={'bottom'} overlay={<Tooltip>Menu</Tooltip>}>
								<Button type="primary" onClick={props.toggleCollapsed} style={{}}>
									{props.collapsed ? <MenuUnfoldOutlined /> : <MenuOutlined />}
								</Button>
							</OverlayTrigger>
						</div>
						<div className="logo">
							<img src={Logo} alt="logo" />
						</div>
					</div>
					<div className="user-menu pt-2">
						<ul>
							{/* <li className="mr-10 dh-xs">
								<Profile {...props} />
							</li> */}
							<li className="mr-10 ">
								<Notifications userId={_id} />
							</li>
							<li>
								<Account {...props} />
							</li>
						</ul>
					</div>
				</nav>

				{/* <div className="menu">
                <Button type="primary" onClick={props.toggleCollapsed} style={{ marginBottom: 16 }}>
                    {props.collapsed ? <MenuUnfoldOutlined /> : <MenuOutlined />}
                </Button>
            </div>
            <div className="tools">
            </div> */}
			</div>
		</header>
	);
};

export default Header;
