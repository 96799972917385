import * as RESTHelper from './webhelper';

export function b64toBlob(b64Data, contentType, fileName) {
  try {
    contentType = contentType || '';
    const sliceSize = 512;

    // var byteCharacters = Base64.atob(b64Data);
    var imageData = b64Data.toString();
    var byteCharacters = atob(
      imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    );
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
}

export const getProfileImageURL = (user) => {
  if (user && user.photo && user.photo.length > 0) {
    return user.photo[0].url;
  }
  return createProfileImageURL(user._id);
};

export const createProfileImageURL = (userId) => {
  const { REACT_APP_MEDIA_DOMAIN: MEDIA_DOMAIN } = process.env;
  return `${MEDIA_DOMAIN}/profile/${userId}/${userId}.png`;
};

export const getMultimedia = (url, cookies) => {
  console.log('getMultimedia', url, cookies);
  if (url) {
    fetchMultimediaBinaryData(url, cookies);
  }
};

const fetchMultimediaBinaryData = (url, cookies) => {
  console.log('fetchMultimediaBinaryData', url, cookies);
  RESTHelper.get(url, {
    avoidToken: true,
    responseType: 'blob',
    withCredentials: true,
    headers: {
      // Cookie: `CloudFront-Key-Pair-Id=K6PZQX7OYVMK6; CloudFront-Signature=fQRcochHt4eM5bi2jTg0LYR3tFAjdXfg0rZPIFN-TvQycDYfvFDq8Z4bkzs-NZ5msEb1N-sKFHx7HLX44QwFnlok1AkosEeMOEE-bM8F7wpmLDu6GSaz4sB-vka9vEEzKo9ACOyUALnOnGoMKotKZegapGIu51B8NwaHW3r-DYdOvSz5KIgFgWt8B~4bcIq7b789hgeKwrKCrGge21TpklM5PUfYKcRKwptrz58rWehpH4z0L8hpNTEz-jd20eggUmDwtvIYtwQVTN1qDgmL6cellEm~31KjI7Y4k9kDApC8U4UouiIEpQxo8QmNisJq-m~IyK4irTbI3WbMVT4x5w__; CloudFront-Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9kZXYudG9teWZhbWlseS5jb20vKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTYxNTI0MTk4NjA2M319fV19;`,
      Cookie: cookies,
    },
  })
    .then((res) => {
      console.log(res);
      if (res.data.code && (res.data.code === 200 || res.data.code === 201)) {
        return BlobToBase64(res.data);
      }
      console.log('error 01', res);
      // todo - for 403 - need to get cookies from action dispatch
    })
    .catch((err) => {
      console.log('error 02', err);
    });
};

const BlobToBase64 = (blobData) => {
  console.log('convertBinaryToBase64', blobData);
  const reader = new FileReader();
  reader.readAsDataURL(blobData);
  reader.onloadend = function () {
    var base64FullString = reader.result;
    console.log('Base64 full string - ', base64FullString);

    // without additional data: Attributes.
    const cleanedB64 = base64FullString.substr(
      base64FullString.indexOf(', ') + 1
    );
    console.log('Base64 String without Tags- ', cleanedB64);
    return cleanedB64;
  };
};
