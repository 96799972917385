import React from 'react';
import PropTypes from 'prop-types';

const FloatingButton = ({
  clickHandler,
  children = null,
  defaultButtonTitle = 'Add',
}) => {
  const getChildren = () => {
    if (children !== null && children !== undefined) {
      return children;
    } else {
      return (
        <button data-test="default-floating-button">
          {defaultButtonTitle}
        </button>
      );
    }
  };

  return (
    <div
      className="floating-but"
      onClick={clickHandler}
      data-test="component-floating-button"
    >
      {getChildren()}
    </div>
  );
};

FloatingButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export { FloatingButton };
