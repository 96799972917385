import settingTypes from '../types/settings';
import commonTypes from '../types/common';

export const initialState = {
  error: '',
  isEnabledPersonalInfoEdit: false,
  isEnabledSecurityInfoEdit: false,
  accountProfilePic: null
};

export const settings = (state = initialState, action) => {
  switch (action.type) {
    case settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
      };
    case settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_FAIL:
      return { ...state, error: action.payload };

    case settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_SUCCESS:
      return {
        ...state,
      };
    case settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_FAIL:
      return { ...state, error: action.payload };

    case settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_EDITABLE_STATE:
      return { ...state, isEnabledPersonalInfoEdit: action.payload };
    case settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_EDITABLE_STATE:
      return { ...state, isEnabledSecurityInfoEdit: action.payload };

    case settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE:
      return { ...state, error: '' };
    case settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_SUCCESS:
      return { ...state, error: '' };
    case settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_FAIL:
      return { ...state, error: action.payload };

    case settingTypes.RESET_SETTING_PAGE:
      return { ...initialState };

    case settingTypes.UPDATE_NEWLY_ADDED_PROFILE_PIC_IN_ACCOUNT:
      return {...state, accountProfilePic: action.payload }

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
