import { createSelector } from 'reselect';

const authDomain = ({ auth }) => auth;

const user = createSelector([authDomain], (authState) => {
  if (authState.user) {
    return authState.user;
  }
  return {};
});

const userRegistrationStep = createSelector([authDomain], (authState) => {
  if (authState.userRegistrationStep) {
    return authState.userRegistrationStep;
  }
  return 0;
});

const profile_Pic = createSelector([authDomain], (authState) => {
  if (authState.profile_Pic) {
    return authState.profile_Pic;
  }
  return {};
});
const forgotPasswordData = createSelector([authDomain], (authState) => {
  if (authState.forgotPasswordData) {
    return authState.forgotPasswordData;
  }
  return {};
});

const law_firms = createSelector([authDomain], (authState) => {
  if (authState.law_firms) {
    let lawFirmList = [];
    if (authState.law_firms.length > 0) {
      authState.law_firms.map((item) => {
        lawFirmList.push({
          label: item.name,
          value: item._id,
          secondaryLabel: [item.state, item.registrationNumber],
        });
      });
    }
    return lawFirmList;
  }
  return [];
});

const law_firms_search_type = createSelector([authDomain], (authState) => {
  if (authState.law_firms_search_type) {
    return authState.law_firms_search_type;
  }
  return 'search';
});

const lawyer = createSelector([authDomain], (authState) => {
  if (authState.lawyer) {
    return authState.lawyer;
  }
  return {};
});

const countries = createSelector([authDomain], (authState) => {
  if (authState.countries) {
    const CountryStates = {
      countryList: [],
      stateList: [],
    };
    if (authState.countries.length > 0) {
      authState.countries.map((item, key) => {
        CountryStates.countryList.push({ label: item.name, value: item.name });
        CountryStates.stateList[item.name] = item.states;
      });
    }
    return CountryStates;
  }
  return {};
});

const isModalOpenCreateLawFirm = createSelector([authDomain], (authState) => {
  return authState.isModalOpenCreateLawFirm;
});

const signedCookies = createSelector([authDomain], (authState) => {
  return authState.signedCookies;
});

const cookieExpireTime = createSelector([authDomain], (authState) => {
  return authState.cookieExpireTime;
});

const firebaseToken = createSelector([authDomain], (authState) => {
  return authState.firebaseToken;
});

export {
  user,
  userRegistrationStep,
  profile_Pic,
  forgotPasswordData,
  law_firms,
  law_firms_search_type,
  lawyer,
  countries,
  isModalOpenCreateLawFirm,
  signedCookies,
  cookieExpireTime,
  firebaseToken
};
