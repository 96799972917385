module.exports = {
  GET_WILL_FINALIZE_PAGE_COMMON_DATA_SUCCESS:
    'GET_WILL_FINALIZE_PAGE_COMMON_DATA_SUCCESS',
  GET_WILL_FINALIZE_PAGE_COMMON_DATA_FAIL:
    'GET_WILL_FINALIZE_PAGE_COMMON_DATA_FAIL',

  UPDATE_WILL_FINALIZE_PAGE_COMMON_DATA_SUCCESS:
    'UPDATE_WILL_FINALIZE_PAGE_COMMON_DATA_SUCCESS',
  UPDATE_WILL_FINALIZE_PAGE_COMMON_DATA_FAIL:
    'UPDATE_WILL_FINALIZE_PAGE_COMMON_DATA_FAIL',

  GET_UPLOADED_SIGNED_WILL_ATTACHMENTS:
    'GET_UPLOADED_SIGNED_WILL_ATTACHMENTS',
  GET_UPLOADED_SIGNED_WILL_ATTACHMENTS_FAIL:
    'GET_UPLOADED_SIGNED_WILL_ATTACHMENTS_FAIL',

  ADD_SIGNED_WILL_ATTACHMENT_SUCCESS:
    'ADD_SIGNED_WILL_ATTACHMENT_SUCCESS',
  ADD_SIGNED_WILL_ATTACHMENT_FAIL:
    'ADD_SIGNED_WILL_ATTACHMENT_FAIL',
  SIGNED_WILL_ATTACHMENT_UPLOADING_START:
    'SIGNED_WILL_ATTACHMENT_UPLOADING_START',
  UPLOAD_SIGNED_WILL_ATTACHMENT_SUCCESS:
    'UPLOAD_SIGNED_WILL_ATTACHMENT_SUCCESS',
  UPLOAD_SIGNED_WILL_ATTACHMENT_FAIL:
    'UPLOAD_SIGNED_WILL_ATTACHMENT_FAIL',
  SIGNED_WILL_ATTACHMENT_UPLOADING_PROGRESS:
    'SIGNED_WILL_ATTACHMENT_UPLOADING_PROGRESS',

  DELETE_SIGNED_WILL_ATTACHMENT_SUCCESS:
    'DELETE_SIGNED_WILL_ATTACHMENT_SUCCESS',
  DELETE_SIGNED_WILL_ATTACHMENT_FAIL:
    'DELETE_SIGNED_WILL_ATTACHMENT_FAIL',

  SEND_LAWYER_INVITATIONS_FROM_FINALIZE_WILL_SUCCESS:
    'SEND_LAWYER_INVITATIONS_FROM_FINALIZE_WILL_SUCCESS',
  SEND_LAWYER_INVITATIONS_FROM_FINALIZE_WILL_FAIL:
    'SEND_LAWYER_INVITATIONS_FROM_FINALIZE_WILL_FAIL',

  NOTIFY_EXECUTORS_FROM_FINALIZE_WILL_SUCCESS:
    'NOTIFY_EXECUTORS_FROM_FINALIZE_WILL_SUCCESS',
  NOTIFY_EXECUTORS_FROM_FINALIZE_WILL_FAIL:
    'NOTIFY_EXECUTORS_FROM_FINALIZE_WILL_FAIL',

  FINALIZE_WILL_OPEN_MODAL:
    'FINALIZE_WILL_OPEN_MODAL',
  FINALIZE_WILL_CLOSE_MODAL:
    'FINALIZE_WILL_CLOSE_MODAL',

  SIGNED_WILL_ATTACHMENT_UPLOADING_CANCEL_BY_USER:
    'SIGNED_WILL_ATTACHMENT_UPLOADING_CANCEL_BY_USER',

  GET_FINALIZE_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS:
    'GET_FINALIZE_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS',
  GET_FINALIZE_PAGE_GENERATED_WILL_DOCUMENT_FAIL:
    'GET_FINALIZE_PAGE_GENERATED_WILL_DOCUMENT_FAIL',

  RESET_WILL_FINALIZE_PAGE: 'RESET_WILL_FINALIZE_PAGE',
};
