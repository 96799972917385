module.exports = {
  GET_TIMELINE: 'GET_TIMELINE',
  GET_TIMELINE_SUCCESS: 'GET_TIMELINE_SUCCESS',
  GET_TIMELINE_FAIL: 'GET_TIMELINE_FAIL',

  CREATE_STORY: 'CREATE_STORY',
  CREATE_STORY_SUCCESS: 'CREATE_STORY_SUCCESS',
  CREATE_STORY_FAIL: 'CREATE_STORY_FAIL',

  UPDATE_STORY: 'UPDATE_STORY',
  UPDATE_STORY_SUCCESS: 'UPDATE_STORY_SUCCESS',
  UPDATE_STORY_FAIL: 'UPDATE_STORY_FAIL',

  DELETE_STORY: 'DELETE_STORY',
  DELETE_STORY_SUCCESS: 'DELETE_STORY_SUCCESS',
  DELETE_STORY_FAIL: 'DELETE_STORY_FAIL',

  SET_ACTIVE_STORY: 'SET_ACTIVE_STORY',
  CLEAR_ACTIVE_STORY: 'CLEAR_ACTIVE_STORY',
};
