import probateWillWillInfoTypes from '../../types/probateWill/willInfo';
import probateWillCommonTypes from '../../types/probateWill/common';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  uploadExistingPreSignedURLs: null,
  generatedWillHTML: null,
  uploadedSignedWills: [],
  uploadedSignedWillURL: null,
};

export const willInfo = (state = initialState, action) => {
  switch (action.type) {
    case probateWillWillInfoTypes.PROBATE_GET_UPLOAD_EXISTING_WILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadExistingPreSignedURLs: action.payload,
      };
    case probateWillWillInfoTypes.PROBATE_GET_UPLOAD_EXISTING_WILL_DOCUMENT_FAIL:
      return { ...state, error: action.payload };

    case probateWillWillInfoTypes.PROBATE_GET_UPLOADED_SIGNED_WILL_SUCCESS:
      return {
        ...state,
        uploadedSignedWills: action.payload,
      };
    case probateWillWillInfoTypes.PROBATE_GET_UPLOADED_SIGNED_WILL_FAIL:
      return { ...state, error: action.payload };
    case probateWillWillInfoTypes.PROBATE_GET_UPLOADED_SIGNED_WILL_PRESIGNED_URL_SUCCESS:
      return {
        ...state,
        uploadedSignedWillURL: action.payload,
      };
    case probateWillWillInfoTypes.PROBATE_GET_UPLOADED_SIGNED_WILL_PRESIGNED_URL_FAIL:
      return { ...state, error: action.payload };

    case probateWillWillInfoTypes.PROBATE_GET_GENERATED_WILL_DOCUMENT_SUCCESS:
      return {
        ...state,
        generatedWillHTML: action.payload.generatedWillHTML,
      };
    case probateWillWillInfoTypes.PROBATE_GET_GENERATED_WILL_DOCUMENT_FAIL:
      return { ...state, error: action.payload };

    case probateWillWillInfoTypes.RESET_PROBATE_WILL_WILL_PREVIEW_DATA:
      return { ...initialState };

    case probateWillCommonTypes.RESET_PROBATE_WILL:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
