import * as ApiConnector from '../../libs/apiConnector';
import { ROUTES } from '../../config';
import authTypes from '../types/authStates';
import { RESPONSE_ALERT } from '../types/common';
import { showResponseMessage, clearStorage, uploadFileToS3 } from './common';
import { authUser } from '../../libs/validation';

export function userRegistration(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.USER_SIGNUP, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.SIGNUP}`,
				data,
				{ avoidToken: true },
				dispatch
			);

			if (response && response.status === 200) {
				localStorage.setItem('token', response.data.data.token);
				dispatch({
					type: authTypes.USER_SIGNUP_SUCCESS,
					payload: response.data.data.user,
				});
				userRegistrationStep(dispatch, 1);
				dispatch(getFirebaseAuthenticationToken());
				dispatch(getSignedCookie());
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.USER_SIGNUP_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('userRegistration', exception);
			dispatch({ type: authTypes.USER_SIGNUP_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

function userRegistrationStep(dispatch, step) {
	console.log('========================');
	// return function (dispatch) {
	dispatch({ type: authTypes.USER_SIGNUP_STEP, payload: step });
	// }
}

const getFirebaseAuthenticationToken = () => async (dispatch) => {
	try {
		const response = await ApiConnector.post(
			`${ROUTES.FIREBASE_AUTHENTICATION_URL}`,
			{},
			{ headers: { 'Content-Type': 'application/json' } },
			dispatch
		);
		if (response && response.data.success) {
      dispatch({ type: authTypes.SET_FIREBASE_TOKEN, payload: response.data.data.token });
      // localStorage.setItem('firebase-token', response.data.data.token)
		}
	} catch (error) {
		console.error(error);
	}
};

export function userLogin(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.USER_SIGNIN, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.LOGIN}`,
				data,
				{ avoidToken: true },
				dispatch
			);

			if (response && response.status === 200) {
				localStorage.setItem('token', response.data.data.token);
				dispatch({
					type: authTypes.USER_SIGNIN_SUCCESS,
					payload: response.data.data.user,
				});
				dispatch(getSignedCookie());
				dispatch(getFirebaseAuthenticationToken());
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.USER_SIGNIN_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('userLogin', exception);
			dispatch({ type: authTypes.USER_SIGNUP_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function getSignedCookie() {
	return async function (dispatch) {
		dispatch({ type: authTypes.USER_GET_SIGNED_COOKIES, payload: true });
		try {
			const response = await ApiConnector.get(
				`${ROUTES.MEDIA}/get-cookies`,
				{ headers: { 'Content-Type': 'application/json' } },
				dispatch
			);

			if (response && response.status === 200) {
				console.log('getSignedCookie', response);
				dispatch({
					type: authTypes.USER_GET_SIGNED_COOKIES_SUCCESS,
					payload: {
						cookies: response.data.cookies,
						expireTime: response.data.expireTime,
					},
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({
					type: authTypes.USER_GET_SIGNED_COOKIES_FAIL,
					payload: msg,
				});
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('userLogin', exception);
			dispatch({
				type: authTypes.USER_GET_SIGNED_COOKIES_FAIL,
				payload: exception,
			});
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function userProfilePic(data, step) {
	if (data) {
		const obj = {
			contentType: 'application/octet-stream',
			imageKey: data.imageKey,
		};
		return async function (dispatch) {
			dispatch({ type: authTypes.USER_UPLOAD_PROFILE_PIC, payload: true });
			try {
				const response = await ApiConnector.post(
					`${ROUTES.PERSIGNED_URL}`,
					obj,
					{ headers: { 'Content-Type': 'application/json' } },
					dispatch
				);
				if (response && response.status === 200) {
					const uploadSuccess = await uploadFileToS3(
						response.data.data.preSingedURL,
						data.file,
						'application/png',
						dispatch
					);
					if (uploadSuccess) {
						dispatch({
							type: authTypes.USER_UPLOAD_PROFILE_PIC_SUCCESS,
							payload: response.data.data,
						});
						dispatch({
							type: authTypes.USER_SIGNIN_SUCCESS,
							payload: response.data.data.user,
						});
					} else {
						dispatch({
							type: authTypes.USER_UPLOAD_PROFILE_PIC_FAIL,
							payload: 'Failed to upload file to S3',
						});
						showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, 'Failed to upload file to S3');
					}
				} else {
					const msg = response.data && response.data.message ? response.data.message : '';
					dispatch({
						type: authTypes.USER_UPLOAD_PROFILE_PIC_FAIL,
						payload: msg,
					});
					showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
				}
			} catch (exception) {
				console.log('userProfilePic', exception);
				dispatch({
					type: authTypes.USER_UPLOAD_PROFILE_PIC_FAIL,
					payload: exception,
				});
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
			}
		};
	} else {
		return function (dispatch) {
			userRegistrationStep(dispatch, 3);
		};
	}
}

export function userVerify(data, step) {
	return async function (dispatch) {
		dispatch({ type: authTypes.USER_VERIFY, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.VERIFY}`,
				data,
				{ headers: { 'Content-Type': 'application/json' }, avoidToken: true },
				dispatch
			);

			if (response && response.status === 200) {
				dispatch({
					type: authTypes.USER_VERIFY_SUCCESS,
					payload: response.data.data,
				});
				if (step) userRegistrationStep(dispatch, 2);
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.USER_VERIFY_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('userProfilePic', exception);
			dispatch({ type: authTypes.USER_VERIFY_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function resendVerifyEmail(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.RESEND_VERIFY_EMAIL, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.RESEND_EMAIL}`,
				data,
				{ headers: { 'Content-Type': 'application/json' }, avoidToken: true },
				dispatch
			);

			if (response && response.status === 200) {
				dispatch({
					type: authTypes.RESEND_VERIFY_EMAIL_SUCCESS,
					payload: true,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.RESEND_VERIFY_EMAIL_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('userProfilePic', exception);
			dispatch({
				type: authTypes.RESEND_VERIFY_EMAIL_FAIL,
				payload: exception,
			});
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function sendForgotPasswordCode(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.SEND_FORGOT_PASSWORD_CODE, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.RESEND_EMAIL}`,
				data,
				{
					headers: { 'Content-Type': 'application/json' },
					avoidToken: true,
				},
				dispatch
			);

			if (response && response.status === 200) {
				dispatch({
					type: authTypes.SEND_FORGOT_PASSWORD_CODE_SUCCESS,
					payload: response.data.data,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({
					type: authTypes.SEND_FORGOT_PASSWORD_CODE_FAIL,
					payload: msg,
				});
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('sendForgotPasswordCode', exception);
			dispatch({
				type: authTypes.RESEND_VERIFY_EMAIL_FAIL,
				payload: exception,
			});
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function changePassword(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.CHANGE_PASSWORD, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.FORGOT_PASSWORD}`,
				data,
				{
					headers: { 'Content-Type': 'application/json' },
					avoidToken: true,
				},
				dispatch
			);

			if (response && response.status === 200) {
				dispatch({ type: authTypes.CHANGE_PASSWORD_SUCCESS, payload: true });
				showResponseMessage(
					dispatch,
					RESPONSE_ALERT.SUCCESS,
					'New password created. Please sign in!'
				);
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.CHANGE_PASSWORD_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('changePassword', exception);
			dispatch({
				type: authTypes.RESEND_VERIFY_EMAIL_FAIL,
				payload: exception,
			});
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function logout() {
	return function (dispatch) {
		localStorage.clear('token');
		clearStorage(dispatch);
	};
}

export function addLawFirm(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.ADD_LAW_FIRM, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.ADD_LAW_FIRM}`,
				data,
				{ headers: { 'Content-Type': 'application/json' } },
				dispatch
			);

			if (response && response.status === 200) {
				localStorage.setItem(
					'addedLawFirmToken',
					response.data.data.lawFirm._id + 'FIRMNAME' + response.data.data.lawFirm.name
				);
				showResponseMessage(dispatch, RESPONSE_ALERT.SUCCESS, 'Law firm registered successfully!');
				dispatch({
					type: authTypes.ADD_LAW_FIRM_SUCCESS,
					payload: response.data.data.lawFirm,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.ADD_LAW_FIRM_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('addLawFirm', exception);
			dispatch({ type: authTypes.ADD_LAW_FIRM_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function getLawFirms(data, type) {
	return async function (dispatch) {
		dispatch({ type: authTypes.GET_LAW_FIRMS, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.GET_LAW_FIRMS}`,
				data,
				{ avoidToken: true },
				dispatch
			);

			if (response && response.status === 200) {
				dispatch({
					type: authTypes.GET_LAW_FIRMS_SUCCESS,
					payload: { lawFirms: response.data.data.lawFirms, type: type },
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.GET_LAW_FIRMS_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('getLawFirms', exception);
			dispatch({ type: authTypes.GET_LAW_FIRMS_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function addLawyerRegistrationData(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.ADD_LAWYER, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.ADD_LAWYER}`,
				data,
				{ headers: { 'Content-Type': 'application/json' } },
				dispatch
			);

			if (response && response.status === 200) {
				//showResponseMessage(dispatch, RESPONSE_ALERT.SUCCESS, 'Registered successfully!')
				dispatch({
					type: authTypes.ADD_LAWYER_SUCCESS,
					payload: response.data.data.user.lawyerDetails,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.ADD_LAWYER_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('addLawyerRegistrationData', exception);
			dispatch({ type: authTypes.ADD_LAWYER_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function getCountries(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.GET_COUNTRIES, payload: true });
		try {
			const response = await ApiConnector.get(
				`${ROUTES.GET_COUNTRIES}`,
				data,
				{ avoidToken: true },
				dispatch
			);
			if (response && response.status === 200) {
				dispatch({
					type: authTypes.GET_COUNTRIES_SUCCESS,
					payload: response.data.data.Countries,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.GET_COUNTRIES_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('getCountries', exception);
			dispatch({ type: authTypes.GET_COUNTRIES_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function inviteLawyers(data) {
	return async function (dispatch) {
		dispatch({ type: authTypes.INVITE_LAWYERS, payload: true });
		try {
			const response = await ApiConnector.post(
				`${ROUTES.INVITE_LAWYERS}`,
				data,
				{ headers: { 'Content-Type': 'application/json' } },
				dispatch
			);
			if (response && response.status === 200) {
				showResponseMessage(dispatch, RESPONSE_ALERT.SUCCESS, 'Invites successfully sent!');
				dispatch({
					type: authTypes.INVITE_LAWYERS_SUCCESS,
					payload: response.data.data,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({ type: authTypes.INVITE_LAWYERS_FAIL, payload: msg });
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			console.log('inviteLawyers', exception);
			dispatch({ type: authTypes.INVITE_LAWYERS_FAIL, payload: exception });
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
	};
}

export function closeModalRegisterLawFirm() {
	return function (dispatch) {
		dispatch({ type: authTypes.CLOSE_MODAL_REGISTER_LAW_FIRM });
	};
}

export function openModalRegisterLawFirm() {
	return function (dispatch) {
		dispatch({ type: authTypes.OPEN_MODAL_REGISTER_LAW_FIRM });
	};
}
