module.exports = {
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_DASHBOARD_DATA_SUCCESS: 'GET_DASHBOARD_DATA_SUCCESS',
  GET_DASHBOARD_DATA_FAIL: 'GET_DASHBOARD_DATA_FAIL',

  GET_REVISION_LOGS: 'GET_REVISION_LOGS',
  GET_REVISION_LOGS_SUCCESS: 'GET_REVISION_LOGS_SUCCESS',
  GET_REVISION_LOGS_FAIL: 'GET_REVISION_LOGS_FAIL',

  GET_CHECKLIST_DATA: 'GET_CHECKLIST_DATA',
  GET_CHECKLIST_DATA_SUCCESS: 'GET_CHECKLIST_DATA_SUCCESS',
  GET_CHECKLIST_DATA_FAIL: 'GET_CHECKLIST_DATA_FAIL',

  GET_MY_ACTIVE_DEATH_REQUEST: 'GET_MY_ACTIVE_DEATH_REQUEST',
  GET_MY_ACTIVE_DEATH_REQUEST_SUCCESS: 'GET_MY_ACTIVE_DEATH_REQUEST_SUCCESS',
  GET_MY_ACTIVE_DEATH_REQUEST_FAIL: 'GET_MY_ACTIVE_DEATH_REQUEST_FAIL',

  REJECT_MY_DEATH_REQUEST: 'REJECT_MY_DEATH_REQUEST',
  REJECT_MY_DEATH_REQUEST_SUCCESS: 'REJECT_MY_DEATH_REQUEST_SUCCESS',
  REJECT_MY_DEATH_REQUEST_FAIL: 'REJECT_MY_DEATH_REQUEST_FAIL',

  DEATH_REQUEST_MODAL_STATUS: 'DEATH_REQUEST_MODAL_STATUS',
};
