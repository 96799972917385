import authTypes from '../types/authStates';
import commonTypes from '../types/common';

export const initialState = {
	load: false,
	error: '',
	user: {},
	profile_Pic: {},
	forgotPasswordData: {
		status: false,
		email: '',
		updateStatus: false,
	},
	law_firms: [],
	law_firms_search_type: 'search',
	lawyer: {},
	countries: [],
	isModalOpenCreateLawFirm: false,
	userRegistrationStep: 0,
	signedCookies: null,
	cookieExpireTime: null,
	firebaseToken: null,
};

export const auth = (state = initialState, action) => {
	switch (action.type) {
		case authTypes.USER_SIGNUP:
			return { ...state, load: action.payload, error: '' };
		case authTypes.USER_SIGNUP_SUCCESS:
			return { ...state, user: action.payload, error: '' };
		case authTypes.USER_SIGNUP_FAIL:
			return { ...state, error: action.payload };
		case authTypes.USER_SIGNUP_STEP:
			return { ...state, userRegistrationStep: action.payload };

		case authTypes.UPDATE_PROFILE_INFO:
			return { ...state, user: action.payload };

		case commonTypes.CLEAR_STORAGE:
			return { ...initialState };

		case authTypes.USER_SIGNIN:
			return { ...state, load: action.payload, error: '' };
		case authTypes.USER_SIGNIN_SUCCESS:
			return { ...state, user: action.payload, error: '' };
		case authTypes.USER_SIGNIN_FAIL:
			return { ...state, error: action.payload };

		case authTypes.USER_GET_SIGNED_COOKIES:
			return {
				...state,
				signedCookies: null,
				cookieExpireTime: null,
			};
		case authTypes.USER_GET_SIGNED_COOKIES_SUCCESS:
			return {
				...state,
				signedCookies: action.payload.cookies,
				cookieExpireTime: action.payload.expireTime,
			};
		case authTypes.USER_GET_SIGNED_COOKIES_FAIL:
			return {
				...state,
				error: action.payload,
				signedCookies: null,
				cookieExpireTime: null,
			};

		case authTypes.USER_UPLOAD_PROFILE_PIC:
			return { ...state, load: action.payload, error: '' };
		case authTypes.USER_UPLOAD_PROFILE_PIC_SUCCESS:
			return { ...state, profile_Pic: action.payload, error: '' };
		case authTypes.USER_UPLOAD_PROFILE_PIC_FAIL:
			return { ...state, error: action.payload };

		case authTypes.USER_VERIFY:
			return { ...state, load: action.payload, error: '' };
		case authTypes.USER_VERIFY_SUCCESS:
			return {
				...state,
				user: { ...state.user, isEmailVerified: true },
				error: '',
			};
		case authTypes.USER_VERIFY_FAIL:
			return { ...state, error: action.payload };

		case authTypes.RESEND_VERIFY_EMAIL:
			return { ...state, load: action.payload, error: '' };
		case authTypes.RESEND_VERIFY_EMAIL_SUCCESS:
			return { ...state, load: false, error: '' };
		case authTypes.RESEND_VERIFY_EMAIL_FAIL:
			return { ...state, error: action.payload };

		case authTypes.SEND_FORGOT_PASSWORD_CODE:
			return { ...state, load: action.payload, error: '' };
		case authTypes.SEND_FORGOT_PASSWORD_CODE_SUCCESS:
			return {
				...state,
				forgotPasswordData: {
					status: true,
					email: action.payload.user.email,
					updateStatus: false,
				},
				load: false,
				error: '',
			};
		case authTypes.RESEND_VERIFY_EMAIL_FAIL:
			return { ...state, error: action.payload };

		case authTypes.CHANGE_PASSWORD:
			return { ...state, load: action.payload, error: '' };
		case authTypes.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				forgotPasswordData: { status: true, email: '', updateStatus: true },
				load: false,
				error: '',
			};
		case authTypes.CHANGE_PASSWORD_FAIL:
			return { ...state, error: action.payload };

		case authTypes.GET_LAW_FIRMS:
			return { ...state, load: action.payload, error: '' };
		case authTypes.GET_LAW_FIRMS_SUCCESS:
			const lawFirmsArray =
				action.payload.type == 'scroll'
					? [...state.law_firms, ...action.payload.lawFirms]
					: action.payload.lawFirms;
			return {
				...state,
				law_firms: lawFirmsArray,
				law_firms_search_type: action.payload.type,
				error: '',
			};
		case authTypes.GET_LAW_FIRMS_FAIL:
			return { ...state, error: action.payload };

		case authTypes.ADD_LAW_FIRM:
			return { ...state, load: action.payload, error: '' };
		case authTypes.ADD_LAW_FIRM_SUCCESS:
			return {
				...state,
				law_firms: [...state.law_firms, action.payload],
				error: '',
			};
		case authTypes.ADD_LAW_FIRM_FAIL:
			return { ...state, error: action.payload };

		case authTypes.ADD_LAWYER:
			return { ...state, load: action.payload, error: '' };
		case authTypes.ADD_LAWYER_SUCCESS:
			return { ...state, lawyer: action.payload, error: '' };
		case authTypes.ADD_LAWYER_FAIL:
			return { ...state, error: action.payload };

		case authTypes.GET_COUNTRIES:
			return { ...state, load: action.payload, error: '' };
		case authTypes.GET_COUNTRIES_SUCCESS:
			return { ...state, countries: action.payload, error: '' };
		case authTypes.GET_COUNTRIES_FAIL:
			return { ...state, error: action.payload };

		case authTypes.INVITE_LAWYERS:
			return { ...state, load: action.payload, error: '' };
		case authTypes.INVITE_LAWYERS_SUCCESS:
			return { ...state, load: false, error: '' };
		case authTypes.INVITE_LAWYERS_FAIL:
			return { ...state, error: action.payload };

		case authTypes.OPEN_MODAL_REGISTER_LAW_FIRM:
			return { ...state, isModalOpenCreateLawFirm: true };
		case authTypes.CLOSE_MODAL_REGISTER_LAW_FIRM:
			return { ...state, isModalOpenCreateLawFirm: false };
		case authTypes.SET_FIREBASE_TOKEN:
			return { ...state, firebaseToken: action.payload };
		default:
			return state;
	}
};
