import React, { useState, useEffect, useRef } from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { authUser, decodeToken } from '../../libs/validation';

export default ({ component: C, props: cProps, ...rest }) => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [pageReloadUserSignupSteps] = useState([0, 3]);
  const [unAuthorizedRoute] = useState([
    '/login',
    '/registration',
    '/verify',
    '/lawyer-register',
    '/forgotPassword',
    '/external',
  ]);

  useEffect(() => {
    if (authUser()) {
      let [authError, authResponse] = decodeToken(
        localStorage.getItem('token')
      );
      if (authResponse !== null && authResponse.isEmailVerified)
        setEmailVerified(true);
      else if (
        authResponse !== null &&
        !authResponse.isEmailVerified &&
        cProps.location.pathname == '/registration' &&
        !_.isEmpty(cProps.user) &&
        cProps.user.isEmailVerified &&
        pageReloadUserSignupSteps.includes(cProps.userRegistrationStep)
      ) {
        setEmailVerified(true);
      } else setEmailVerified(false);
    } else {
      setEmailVerified(false);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser() &&
        unAuthorizedRoute.includes(cProps.location.pathname) &&
        emailVerified ? (
          <Redirect to={'/dashboard'} />
        ) : (
          <C {...props} {...cProps} />
        )
      }
    />
  );
};
