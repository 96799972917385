import cloneDeep from 'lodash/cloneDeep';
import createWillCommonTypes from '../../types/createWill/common';
import commonTypes from '../../types/common';

export const initialState = {
	currentStage: 0,
	willId: null,
	willStage: null,
	error: '',
	loading: false,
	countriesAndStates: [],
	isAcknowledgeModalOpen: false,
	jointWillStatus: null,
	isOwner: null,
	acknowledgeChanges: {},
};

export const common = (state = initialState, action) => {
	switch (action.type) {
		case createWillCommonTypes.LOADING:
			return { ...state, loading: action.payload };

		case createWillCommonTypes.GET_WILL:
			return { ...state, willId: action.payload };

		case createWillCommonTypes.SET_WILL:
			return { ...state, willId: action.payload };
		case createWillCommonTypes.SET_WILL_STAGE:
			return { ...state, willStage: action.payload };
		case createWillCommonTypes.RESET_WILL:
			return { ...state, willId: null };

		case createWillCommonTypes.CREATE_WILL:
			return { ...state, willId: action.payload };
		case createWillCommonTypes.CREATE_WILL_FAIL:
			return { ...state, error: action.payload };

		case createWillCommonTypes.WILL_GET_COUNTRIES_SUCCESS:
			return { ...state, countriesAndStates: action.payload };
		case createWillCommonTypes.WILL_GET_COUNTRIES_FAIL:
			return { ...state, error: action.payload };

		case createWillCommonTypes.RESET_WILL_CREATION:
			return {
				...state,
				currentStage: initialState.currentStage,
				willId: initialState.willId,
				willStage: initialState.willStage,
				error: initialState.error,
				loading: initialState.loading,
			};

		case createWillCommonTypes.DELETE_WILL_SUCCESS:
			return {
				...state,
				currentStage: initialState.currentStage,
				willId: initialState.willId,
				willStage: initialState.willStage,
				error: initialState.error,
				loading: initialState.loading,
			};
		case createWillCommonTypes.DELETE_WILL_FAIL:
			return { ...state, error: action.payload };

		case createWillCommonTypes.GOTO_NEXT:
			return { ...state, currentStage: (state.currentStage += 1) };
		case createWillCommonTypes.GOTO_PREVIOUS:
			return { ...state, currentStage: (state.currentStage -= 1) };
		case createWillCommonTypes.GOTO:
			return { ...state, currentStage: action.payload };
		case createWillCommonTypes.TOGGLE_ACKNOWLEDGE_MODAL_VISIBILITY:
			return { ...state, isAcknowledgeModalOpen: action.payload };
		case createWillCommonTypes.UPDATE_JOINT_WILL_STATUS_AND_IS_OWNER_PROPS:
			return {
				...state,
				jointWillStatus: action.payload.jointWillStatus,
				isOwner: action.payload.isOwner,
			};

		case createWillCommonTypes.GET_ACKNOWLEDGE_LOGS:
			return { ...state };
		case createWillCommonTypes.GET_ACKNOWLEDGE_LOGS_SUCCESS:
			const clonedState = cloneDeep(state);
      const { changeLogs, pagination } = action.payload;
			if (pagination.page > 1) {
        changeLogs.forEach(log => clonedState.acknowledgeChanges.changeLogs.push(log))
        clonedState.acknowledgeChanges.pagination = pagination
			} else {
        clonedState.acknowledgeChanges = action.payload;
      }
			return { ...clonedState };
		case createWillCommonTypes.GET_ACKNOWLEDGE_LOGS_FAIL:
			return {
				...state,
			};
		case createWillCommonTypes.CLEAR_ACKNOWLEDGED_LOGS:
			return {
                ...state,
                acknowledgeChanges: {},
            };
		case commonTypes.CLEAR_STORAGE:
			return { ...initialState };

		default:
			return state;
	}
};
