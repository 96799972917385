module.exports = {
  BENEFICIARIES_PAGE_UPDATE_SUCCESS: 'BENEFICIARIES_PAGE_UPDATE_SUCCESS',

  GET_BENEFICIARIES_PAGE_SUCCESS: 'GET_BENEFICIARIES_PAGE_SUCCESS',
  GET_BENEFICIARIES_PAGE_FAIL: 'GET_BENEFICIARIES_PAGE_FAIL',

  UPDATE_BENEFICIARIES_PAGE_SUCCESS: 'UPDATE_BENEFICIARIES_PAGE_SUCCESS',
  UPDATE_BENEFICIARIES_PAGE_FAIL: 'UPDATE_BENEFICIARIES_PAGE_FAIL',

  RESET_WILL_BENIFICIARIES_PAGE: 'RESET_WILL_BENIFICIARIES_PAGE',
};
