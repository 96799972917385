import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { RESPONSE_ALERT } from '../../../store/types/common';

const Alert = ({
  notificationType,
  notification,
  remove = false,
  clearNotification = null,
}) => {
  let type =
    notificationType === RESPONSE_ALERT.SUCCESS
      ? 'success'
      : notificationType === RESPONSE_ALERT.ERROR
      ? 'danger'
      : notificationType === RESPONSE_ALERT.INFO
      ? 'info'
      : 'warning';

  let alertClassName = classNames(
    `alert alert-${type}`,
    remove ? 'alert-dismissible' : ''
  );

  return (
    <div className={alertClassName} role="alert">
      {notification}
      {remove && (
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={clearNotification}
        >
          <span>&times;</span>
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  notificationType: PropTypes.number.isRequired,
  notification: PropTypes.string.isRequired,
};

export { Alert };
