import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import createPagination from '../../../libs/createPagination';

// import './styles.scss';

const Pagination = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  // useEffect(() => {setCurrentPage(1) }, [props.numberOfItems]);
  useEffect(() => {
    if (props.resetPagination) setCurrentPage(1);
  }, [props.resetPagination]);

  const { pagination } = createPagination({
    numberOfItems: props.numberOfItems,
    itemsPerPage: props.itemsPerPage,
    numberOfButtons: props.numberOfButtons,
    currentPage,
  });

  const handleClick = (page) => {
    setCurrentPage(page);
    props.setfromValue((page - 1) * props.itemsPerPage);
  };

  if (
    pagination.length === 0 &&
    props.numberOfItems !== 0 &&
    currentPage - 1 > 0
  ) {
    setCurrentPage(currentPage - 1);
    props.setfromValue((currentPage - 1 - 1) * props.itemsPerPage);
  }
  let divClassName = classNames(
    'mt-25 d-flex justify-content-between align-items-center data-table-pagination',
    props.className
  );
  return (
    <div className={divClassName}>
      <div className="dataTables_info">
        Showing {props.showingCount} to {props.pageCount} of {props.totalCount}{' '}
        entries
      </div>
      <ul className="pagination float-right m-0 p-0">
        <li
          className={`paginate_button page-item previous ${
            pagination[0] === currentPage && 'disabled'
          }`}
          id="dataTableExample_previous"
          onClick={handleClick.bind(null, currentPage - 1)}
        >
          <a className="page-link">Previous</a>
        </li>
        {pagination.map((page, key) => (
          <li
            key={key}
            className={`paginate_button page-item ${
              currentPage === page && 'active'
            }`}
            onClick={handleClick.bind(null, page)}
          >
            <a className="page-link">{page}</a>
          </li>
        ))}
        <li
          className={`paginate_button page-item next ${
            pagination.reverse()[0] === currentPage && 'disabled'
          }`}
          onClick={handleClick.bind(null, currentPage + 1)}
        >
          <a className="page-link">Next</a>
        </li>
      </ul>
    </div>
  );
};

export { Pagination };
