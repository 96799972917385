import isNil from 'lodash/isNil';
import personalTypes from '../../types/createWill/personal';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  primaryUser: {},
  partner: {},
  jointWillStatus: '',
  isOwner: true
};

export const personal = (state = initialState, action) => {
  switch (action.type) {
    case personalTypes.GET_PERSONAL_PAGE_SUCCESS:
      return {
        ...state,
        primaryUser: action.payload.primaryUser,
        partner: action.payload.partner,
        jointWillStatus: !isNil(action.payload.jointWillStatus) ? action.payload.jointWillStatus : 'new',
        isOwner: !isNil(action.payload.isOwner) ? action.payload.isOwner : true
      };
    case personalTypes.GET_PERSONAL_PAGE_FAIL:
      return { ...state, error: action.payload };

    case personalTypes.UPDATE_PERSONAL_PAGE_SUCCESS:
      return {
        ...state,
        primaryUser: action.payload.primaryUser,
        partner: action.payload.partner,
      };
    case personalTypes.UPDATE_PERSONAL_PAGE_FAIL:
      return { ...state, error: action.payload };

    case personalTypes.RESET_WILL_PERSONAL_PAGE:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
