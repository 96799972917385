module.exports = {
  GUARDIANS_PAGE_UPDATE_SUCCESS: 'GUARDIANS_PAGE_UPDATE_SUCCESS',

  GET_GUARDIANS_PAGE_SUCCESS: 'GET_GUARDIANS_PAGE_SUCCESS',
  GET_GUARDIANS_PAGE_FAIL: 'GET_GUARDIANS_PAGE_FAIL',

  UPDATE_GUARDIANS_PAGE_SUCCESS: 'UPDATE_GUARDIANS_PAGE_SUCCESS',
  UPDATE_GUARDIANS_PAGE_FAIL: 'UPDATE_GUARDIANS_PAGE_FAIL',

  RESET_WILL_GUARDIANS_PAGE: 'RESET_WILL_GUARDIANS_PAGE',
};
