import React from 'react';

/**
 * Please don't use following urls, since they are using in cloudFront
 * /videoLogs/*
 * /attachments/*
 * /profile/*
 * /will/*
 */

const LoginContainer = React.lazy(() =>
  import('./containers/auth/LoginContainer')
);
const RegisterContainer = React.lazy(() =>
  import('./containers/auth/RegisterContainer')
);
const VerifyContainer = React.lazy(() =>
  import('./containers/auth/VerifyContainer')
);
const DashboardContainer = React.lazy(() =>
  import('./containers/dashboard/DashboardContainer')
);
const LifeStoryContainer = React.lazy(() =>
  import('./containers/dashboard/LifeStoryContainer')
);
const FuneralPlansContainer = React.lazy(() =>
  import('./containers/dashboard/FuneralPlansContainer')
);
const MyLogsContainer = React.lazy(() =>
  import('./containers/dashboard/MyLogsContainer')
);
const AccountManagerContainer = React.lazy(() =>
  import('./containers/dashboard/AccountManagerContainer')
);
const ForgotPasswordContainer = React.lazy(() =>
  import('./containers/auth/ForgotPasswordContainer')
);
const CreateWillContainer = React.lazy(() =>
  import('./containers/dashboard/CreateWillContainer')
);
const LawyerRegisterContainer = React.lazy(() =>
  import('./containers/auth/LawyerRegisterContainer')
);
const ManageWillContainer = React.lazy(() =>
  import('./containers/dashboard/ManageWillContainer')
);
const ProbateWillContainer = React.lazy(() =>
  import('./containers/dashboard/ProbateWillContainer')
);
const SettingsContainer = React.lazy(() =>
  import('./containers/dashboard/SettingsContainer')
);
const ExternalLoginContainer = React.lazy(() =>
  import('../src/containers/auth/ExternalContainer/Login')
);
const ExternalLogsPreviewContainer = React.lazy(() =>
  import('../src/containers/auth/ExternalContainer/LogsPreview')
);
const BeneficiariesContainer = React.lazy(() =>
  import('./containers/dashboard/CreateWillContainer/Beneficiaries/index')
);
const GuardiansContainer = React.lazy(() =>
  import('./containers/dashboard/CreateWillContainer/Guardians/index')
);
const ExecutorContainer = React.lazy(() =>
  import('./containers/dashboard/CreateWillContainer/Executor/index')
);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginContainer,
    authorizedRoute: false,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: RegisterContainer,
    authorizedRoute: false,
  },
  {
    path: '/verify',
    name: 'Verify',
    component: VerifyContainer,
    authorizedRoute: false,
  },
  {
    path: '/lawyer-register',
    name: 'LawyerRegistration',
    component: LawyerRegisterContainer,
    authorizedRoute: false,
  },
  {
    path: '/forgotPassword',
    name: 'ForgotPassword',
    component: ForgotPasswordContainer,
    authorizedRoute: false,
  },
  { path: '/welcome', name: 'Welcome', component: null, authorizedRoute: true },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardContainer,
    authorizedRoute: true,
  },
  {
    path: '/life-story',
    name: 'LifeStory',
    component: LifeStoryContainer,
    authorizedRoute: true,
  },
  {
    path: '/funeral-plans',
    name: 'FuneralPlans',
    component: FuneralPlansContainer,
    authorizedRoute: true,
  },
  {
    path: '/my-logs',
    name: 'MyLogs',
    component: MyLogsContainer,
    authorizedRoute: true,
  },
  {
    path: '/account-manager',
    name: 'AccountManager',
    component: AccountManagerContainer,
    authorizedRoute: true,
  },
  {
    path: '/create-will',
    name: 'CreateWill',
    component: CreateWillContainer,
    authorizedRoute: true,
  },
  {
    path: '/manage-will',
    name: 'ManageWill',
    component: ManageWillContainer,
    authorizedRoute: true,
  },
  {
    path: '/probate-will',
    name: 'ProbateWill',
    component: ProbateWillContainer,
    authorizedRoute: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsContainer,
    authorizedRoute: true,
  },
  {
    path: '/external/logs/preview',
    name: 'ExternalLogsPreview',
    component: ExternalLogsPreviewContainer,
    authorizedRoute: false,
  },
  {
    path: '/external/logs/:logId',
    name: 'ExternalLogin',
    component: ExternalLoginContainer,
    authorizedRoute: false,
  },
  {
    path: '/beneficiary',
    name: 'beneficiary',
    component: BeneficiariesContainer,
    authorizedRoute: true,
  },
  {
    path: '/guardians',
    name: 'guardians',
    component: GuardiansContainer,
    authorizedRoute: true,
  },
  {
    path: '/executors',
    name: 'executors',
    component: ExecutorContainer,
    authorizedRoute: true,
  },
];

export default routes;
