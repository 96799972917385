module.exports = {
  GET_VIDEO_LOGS: 'GET_VIDEO_LOGS',
  GET_VIDEO_LOGS_SUCCESS: 'GET_VIDEO_LOGS_SUCCESS',
  GET_VIDEO_LOGS_FAIL: 'GET_VIDEO_LOGS_FAIL',
  GET_WRITE_LOGS: 'GET_WRITE_LOGS',
  GET_WRITE_LOGS_SUCCESS: 'GET_WRITE_LOGS_SUCCESS',
  GET_WRITE_LOGS_FAIL: 'GET_WRITE_LOGS_FAIL',

  ADD_VIDEO_LOGS: 'ADD_VIDEO_LOGS',
  ADD_VIDEO_LOGS_SUCCESS: 'ADD_VIDEO_LOGS_SUCCESS',
  ADD_VIDEO_LOGS_FAIL: 'ADD_VIDEO_LOGS_FAIL',
  CREATE_WRITE_LOGS: 'CREATE_WRITE_LOGS',
  CREATE_WRITE_LOGS_FAIL: 'CREATE_WRITE_LOGS_FAIL',
  CREATE_WRITE_LOGS_SUCCESS: 'CREATE_WRITE_LOGS_SUCCESS',

  UPDATE_VIDEO_LOGS: 'UPDATE_VIDEO_LOGS',
  UPDATE_VIDEO_LOGS_SUCCESS: 'UPDATE_VIDEO_LOGS_SUCCESS',
  UPDATE_VIDEO_LOGS_FAIL: 'UPDATE_VIDEO_LOGS_FAIL',
  UPDATE_WRITE_LOGS_FAIL: 'UPDATE_WRITE_LOGS_FAIL',
  UPDATE_WRITE_LOGS_SUCCESS: 'UPDATE_WRITE_LOGS_SUCCESS',

  DELETE_WRITE_LOGS: 'DELETE_WRITE_LOGS',
  DELETE_WRITE_LOGS_FAIL: 'DELETE_WRITE_LOGS_FAIL',
  DELETE_WRITE_LOGS_SUCCESS: 'DELETE_WRITE_LOGS_SUCCESS',
  DELETE_VIDEO_LOGS: 'DELETE_VIDEO_LOGS',
  DELETE_VIDEO_LOGS_SUCCESS: 'DELETE_VIDEO_LOGS_SUCCESS',
  DELETE_VIDEO_LOGS_FAIL: 'DELETE_VIDEO_LOGS_FAIL',

  OPEN_MODAL_VIDEO_LOGS: 'OPEN_MODAL_VIDEO_LOGS',
  CLOSE_MODAL_VIDEO_LOGS: 'CLOSE_MODAL_VIDEO_LOGS',

  OPEN_MODAL_WRITE_LOGS: 'OPEN_MODAL_WRITE_LOGS',
  CLOSE_MODAL_WRITE_LOGS: 'CLOSE_MODAL_WRITE_LOGS',

  UPLOADING_START: 'UPLOADING_START',
  UPLOADING_PROGRESS: 'UPLOADING_PROGRESS',
  UPLOADING_SUCCESS: 'UPLOADING_SUCCESS',
  UPLOADING_FAIL: 'UPLOADING_FAIL',
  UPLOADING_CANCEL_BY_USER: 'UPLOADING_CANCEL_BY_USER',

  GET_VIDEO_LOGS_QUOTA: 'GET_VIDEO_LOGS_QUOTA',
  GET_VIDEO_LOGS_QUOTA_SUCCESS: 'GET_VIDEO_LOGS_QUOTA_SUCCESS',
  GET_VIDEO_LOGS_QUOTA_FAIL: 'GET_VIDEO_LOGS_QUOTA_FAIL',
};
