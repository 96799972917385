module.exports = {
  PROBATE_LOADING: 'PROBATE_LOADING',

  PROBATE_GET_MY_WILLS_SUCCESS: 'PROBATE_GET_MY_WILLS_SUCCESS',
  PROBATE_GET_MY_WILLS_FAIL: 'PROBATE_GET_MY_WILLS_FAIL',

  PROBATE_GET_DEATH_REQUESTS_SUCCESS: 'PROBATE_GET_DEATH_REQUESTS_SUCCESS',
  PROBATE_GET_DEATH_REQUESTS_FAIL: 'PROBATE_GET_DEATH_REQUESTS_FAIL',

  PROBATE_UPDATE_EXECUTOR_JOINING_STATUS_SUCCESS:
    'PROBATE_UPDATE_EXECUTOR_JOINING_STATUS_SUCCESS',
  PROBATE_UPDATE_EXECUTOR_JOINING_STATUS_FAIL:
    'PROBATE_UPDATE_EXECUTOR_JOINING_STATUS_FAIL',

  PROBATE_INFORM_DEATH_SUCCESS: 'PROBATE_INFORM_DEATH_SUCCESS',
  PROBATE_INFORM_DEATH_FAIL: 'PROBATE_INFORM_DEATH_FAIL',

  PROBATE_DEATH_DOCUMENTS_UPLOADING_START:
    'PROBATE_DEATH_DOCUMENTS_UPLOADING_START',
  PROBATE_DEATH_DOCUMENTS_UPLOADING_PROGRESS:
    'PROBATE_DEATH_DOCUMENTS_UPLOADING_PROGRESS',
  PROBATE_DEATH_DOCUMENTS_UPLOADING_SUCCESS:
    'PROBATE_DEATH_DOCUMENTS_UPLOADING_SUCCESS',
  PROBATE_DEATH_DOCUMENTS_UPLOADING_FAIL:
    'PROBATE_DEATH_DOCUMENTS_UPLOADING_FAIL',
  PROBATE_DEATH_DOCUMENTS_UPLOADING_CANCEL_BY_USER:
    'PROBATE_DEATH_DOCUMENTS_UPLOADING_CANCEL_BY_USER',

  PROBATE_DELETE_DEATH_INFORMED_REQUEST_SUCCESS:
    'PROBATE_DELETE_DEATH_INFORMED_REQUEST_SUCCESS',
  PROBATE_DELETE_DEATH_INFORMED_REQUEST_FAIL:
    'PROBATE_DELETE_DEATH_INFORMED_REQUEST_FAIL',

  PROBATE_SET_SELECTED_REQUEST: 'PROBATE_SET_SELECTED_REQUEST',
  PROBATE_SET_SELECTED_USER: 'PROBATE_SET_SELECTED_USER',
  PROBATE_SET_SELECTED_USER_TYPE: 'PROBATE_SET_SELECTED_USER_TYPE',
  PROBATE_SET_EXECUTOR_DATA: 'PROBATE_SET_EXECUTOR_DATA',

  PROBATE_OPEN_INFORM_DEATH_MODAL: 'PROBATE_OPEN_INFORM_DEATH_MODAL',
  PROBATE_CLOSE_INFORM_DEATH_MODAL: 'PROBATE_CLOSE_INFORM_DEATH_MODAL',

  RESET_PROBATE_WILL: 'RESET_PROBATE_WILL',
};
