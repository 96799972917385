import React from 'react';

const Row = ({ children, customClass, style, condition = true }) => {
  return condition ? (
    <div className={customClass || 'row'} style={style}>
      {children}
    </div>
  ) : (
    children
  );
};
export { Row };
