import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store, { persistor } from './store/store';
import { unregister as registerServiceWorker } from './registerServiceWorker';

// import configureStore from './store/store';

// const initialState = {};
// const history = createBrowserHistory();
// const store = configureStore(initialState, history);

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
