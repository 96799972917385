import probateWillCommonTypes from '../../types/probateWill/common';
import probateWillAccountManagerTypes from '../../types/probateWill/accountManager';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  accountManagerData: {},
};

export const accountManager = (state = initialState, action) => {
  switch (action.type) {
    case probateWillAccountManagerTypes.PROBATE_GET_ACCOUNT_MANAGER_SUCCESS:
      return {
        ...state,
        accountManagerData: action.payload,
      };
    case probateWillAccountManagerTypes.PROBATE_GET_ACCOUNT_MANAGER_FAIL:
      return { ...state, error: action.payload };

    case probateWillCommonTypes.RESET_PROBATE_WILL:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
