import commonTypes from '../types/common';
import * as authActions from './auth';
import * as ApiConnector from '../../libs/apiConnector';
import lifeStoryTypes from '../types/lifeStoryStates';

export function clearStorage(dispatch) {
  dispatch({ type: commonTypes.CLEAR_STORAGE, payload: true });
}

export function showResponseMessage(dispatch, type, msg, duration = 3000) {
  dispatch({ type: commonTypes.SHOW, payload: { type: type, msg: msg } });
  setTimeout(() => {
    dispatch({ type: commonTypes.HIDE });
  }, duration);
}

export function sessionLogoutResponseAlert(dispatch) {
  dispatch({ type: commonTypes.SESSION_EXPIRE, payload: true });
}

export function sessionLogout() {
  return function (dispatch) {
    dispatch({ type: commonTypes.SESSION_EXPIRE, payload: false });
    dispatch(authActions.logout());
  };
}

export const uploadFileToS3 = (
  generatePutUrl,
  file,
  contentType,
  dispatch,
  dispatchType = null,
  cancelToken = null,
  attachCookies = false
) => {
  const config = {
    // headers: {
    //   'Content-Type': contentType,
    // },
    // onUploadProgress: (progressEvent) => {
    //   let percentCompleted = Math.round(
    //     (progressEvent.loaded * 100) / progressEvent.total
    //   );
    //   console.log('Completed loaded', progressEvent.loaded);
    //   console.log('Completed total', progressEvent.total);
    //   console.log('Completed percent', percentCompleted);
    //   console.log('Completed progressEvent', progressEvent);
    //   if (dispatchType) {
    //     dispatch({ type: dispatchType, payload: percentCompleted });
    //   }
    // },
    // avoidToken: true,
    // cancelToken: cancelToken,
  };
  config.headers = {
    'Content-Type': contentType,
  };
  config.onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    console.log('Completed loaded', progressEvent.loaded);
    console.log('Completed total', progressEvent.total);
    console.log('Completed percent', percentCompleted);
    console.log('Completed progressEvent', progressEvent);
    if (dispatchType) {
      dispatch({ type: dispatchType, payload: percentCompleted });
    }
  };
  config.avoidToken = true;
  config.cancelToken = cancelToken;
  if (attachCookies) {
    config.withCredentials = true;
    config.headers = {
      'Content-Type': contentType,
      Cookie: document.cookie,
    };
  }
  console.log('config', config);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await ApiConnector.put(
        generatePutUrl,
        file,
        config,
        dispatch
      );
      if (response.status === 200) {
        resolve(true);
      } else {
        reject(false);
      }
    } catch (exception) {
      console.log('Error upload file', exception);
      reject(false);
    }
  }).catch((e) => {
    console.log('Error upload file', e);
  });
};
