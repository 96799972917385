import * as ApiConnector from '../../../libs/apiConnector';
import { ROUTES } from '../../../config';
import { RESPONSE_ALERT } from '../../types/common';
import { showResponseMessage } from '../common';
import createWillCommonTypes from '../../types/createWill/common';
import createWillWelcomeTypes from '../../types/createWill/welcome';
import personalTypes from '../../types/createWill/personal';
import beneficiariesTypes from '../../types/createWill/beneficiaries';
import guardiansTypes from '../../types/createWill/guardians';
import executorTypes from '../../types/createWill/executors';
import summaryTypes from '../../types/createWill/summary';
import finalizeTypes from '../../types/createWill/finalize';

export function resetWillCreation() {
	return function (dispatch) {
		dispatch({ type: createWillCommonTypes.RESET_WILL_CREATION });
		dispatch({ type: createWillWelcomeTypes.RESET_WILL_WELCOMEPAGE });
		dispatch({ type: personalTypes.RESET_WILL_PERSONAL_PAGE });
		dispatch({ type: beneficiariesTypes.RESET_WILL_BENIFICIARIES_PAGE });
		dispatch({ type: guardiansTypes.RESET_WILL_GUARDIANS_PAGE });
		dispatch({ type: executorTypes.RESET_WILL_EXECUTORS_PAGE });
		dispatch({ type: summaryTypes.RESET_WILL_SUMMARY_PAGE });
		dispatch({ type: finalizeTypes.RESET_WILL_FINALIZE_PAGE });
		// register other pages here to reset other wizard details
	};
}
export function goNext() {
	return function (dispatch) {
		dispatch({ type: createWillCommonTypes.GOTO_NEXT });
	};
}
export function goPrevious() {
	return function (dispatch) {
		dispatch({ type: createWillCommonTypes.GOTO_PREVIOUS });
	};
}
export function goto(toStage) {
	return function (dispatch) {
		dispatch({ type: createWillCommonTypes.GOTO, payload: toStage });
	};
}

export function getCountries() {
	return async function (dispatch) {
		dispatch({ type: createWillCommonTypes.LOADING, payload: true });
		try {
			const response = await ApiConnector.get(
				`${ROUTES.GET_COUNTRIES}`,
				{ avoidToken: true },
				dispatch
			);
			if (response && response.status === 200) {
				dispatch({
					type: createWillCommonTypes.WILL_GET_COUNTRIES_SUCCESS,
					payload: response.data.data.Countries,
				});
			} else {
				const msg = response.data && response.data.message ? response.data.message : '';
				dispatch({
					type: createWillCommonTypes.WILL_GET_COUNTRIES_FAIL,
					payload: msg,
				});
				showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, msg);
			}
		} catch (exception) {
			dispatch({
				type: createWillCommonTypes.WILL_GET_COUNTRIES_FAIL,
				payload: exception,
			});
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
		}
		dispatch({ type: createWillCommonTypes.LOADING, payload: false });
	};
}

/**
 * @description use this async func to retrieve change log data to infinite scroll when scrolling
 * @param {object} params
 */
export const fetchChangeLogList = (willId, params) => async (dispatch) => {
	// open change log side pane
	try {
		const response = await ApiConnector.post(
			ROUTES.WILL_CREATION_CHANGE_LOGS + `/${willId}`,
			params,
			dispatch
		);
		if (response && response.data.success) {
			return response.data.data;
		}
		return null;
	} catch (err) {
		return null;
	}
};

export const toggleAcknowledgeModalVisibility = (status) => async (dispatch) => {
	dispatch({ type: createWillCommonTypes.TOGGLE_ACKNOWLEDGE_MODAL_VISIBILITY, payload: status });
};

export const fetchAcknowledgeLogs = (willId, params) => async (dispatch) => {
	try {
		const response = await ApiConnector.post(
			ROUTES.WILL_CREATION_CHANGE_LOGS + `/${willId}`,
			params,
			dispatch
		);
		if (response && response.data.success) {
			if (response.data.data.changeLogs.length > 0) {
				dispatch({
					type: createWillCommonTypes.GET_ACKNOWLEDGE_LOGS_SUCCESS,
					payload: response.data.data,
				});
				dispatch(toggleAcknowledgeModalVisibility(true));
			} else {
				dispatch(toggleAcknowledgeModalVisibility(false));
			}
		} else {
			dispatch({
				type: createWillCommonTypes.GET_ACKNOWLEDGE_LOGS_FAIL,
			});
		}
	} catch (error) {
		dispatch({
			type: createWillCommonTypes.GET_ACKNOWLEDGE_LOGS_FAIL,
		});
	}
};

export const updateIsOwnerAndJointWillStatus = (isOwner, jointWillStatus) => async (dispatch) => {
	dispatch({
		type: createWillCommonTypes.UPDATE_JOINT_WILL_STATUS_AND_IS_OWNER_PROPS,
		payload: { isOwner, jointWillStatus },
	});
};

export const clearAcknowledgedLogs = () => async (dispatch) => {
	dispatch({
		type: createWillCommonTypes.CLEAR_ACKNOWLEDGED_LOGS,
	});
};

/**
 * @description use this async func to acknowledge changes done by partner
 * @param {Array} logIdArr - ids of acknowledged log changes
 */
export const updateAcknowledgedChanges = (logIdArr) => async (dispatch) => {
	// open change log side pane
	try {
		const response = await ApiConnector.put(
			ROUTES.WILL_CREATION_CHANGE_LOGS + '/acknowledge',
			logIdArr,
			dispatch
		);
		if (response && response.data.success) {
			showResponseMessage(dispatch, RESPONSE_ALERT.SUCCESS, response.data.message);
			dispatch(toggleAcknowledgeModalVisibility(false));
		} else {
			console.error(response.data.message);
			showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, response.data.message);
		}
		return null;
	} catch (err) {
		showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, err);
		console.error(err);
	}
};
