module.exports = {
	LOADING: 'LOADING',

	GOTO_NEXT: 'GOTO_NEXT',
	GOTO_PREVIOUS: 'GOTO_PREVIOUS',
	GOTO: 'GOTO',

	CREATE_WILL: 'CREATE_WILL',
	CREATE_WILL_FAIL: 'CREATE_WILL_FAIL',

	DELETE_WILL_SUCCESS: 'DELETE_WILL_SUCCESS',
	DELETE_WILL_FAIL: 'DELETE_WILL_FAIL',

	GET_WILL: 'GET_WILL',
	SET_WILL: 'SET_WILL',
	SET_WILL_STAGE: 'SET_WILL_STAGE',
	RESET_WILL: 'RESET_WILL',

	RESET_WILL_CREATION: 'RESET_WILL_CREATION',

	WILL_GET_COUNTRIES_SUCCESS: 'WILL_GET_COUNTRIES_SUCCESS',
	WILL_GET_COUNTRIES_FAIL: 'WILL_GET_COUNTRIES_FAIL',

	TOGGLE_ACKNOWLEDGE_MODAL_VISIBILITY: 'TOGGLE_ACKNOWLEDGE_MODAL_VISIBILITY',

	UPDATE_JOINT_WILL_STATUS_AND_IS_OWNER_PROPS: 'UPDATE_JOINT_WILL_STATUS_AND_IS_OWNER_PROPS',

	GET_ACKNOWLEDGE_LOGS: 'GET_ACKNOWLEDGE_LOGS',
	GET_ACKNOWLEDGE_LOGS_SUCCESS: 'GET_ACKNOWLEDGE_LOGS_SUCCESS',
	GET_ACKNOWLEDGE_LOGS_FAIL: 'GET_ACKNOWLEDGE_LOGS_FAIL',
	CLEAR_ACKNOWLEDGED_LOGS: 'CLEAR_ACKNOWLEDGED_LOGS',
};
