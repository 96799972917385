import accountManagerTypes from '../types/accountManagerStates';
import commonTypes from '../types/common';

export const initialState = {
  load: false,
  error: '',
  accountManagerId: '',
  socialMediaAccounts: [],
  otherAccounts: [],
  executorAccess: false,
  isModalOpen: false,
  otherAccountIsEditable: false,
};

export const accountManager = (state = initialState, action) => {
  switch (action.type) {
    case accountManagerTypes.ACCOUNT_MANAGER_SUCCESS:
      return {
        ...state,
        load: false,
        error: '',
        accountManagerId: action.payload._id,
        socialMediaAccounts: action.payload.socialMedia,
        otherAccounts: action.payload.otherAccounts,
        executorAccess: action.payload.executor_access ? true : false,
      };

    case accountManagerTypes.GET_ACCOUNT_MANAGER:
      return { ...state, load: true, error: '' };
    case accountManagerTypes.GET_ACCOUNT_MANAGER_FAIL:
      return { ...state, load: false, error: action.payload };

    case accountManagerTypes.ADD_ACCOUNT_MANAGER:
      return { ...state, load: true, error: '' };
    case accountManagerTypes.ADD_ACCOUNT_MANAGER_FAIL:
      return { ...state, load: false, error: action.payload };

    case accountManagerTypes.UPDATE_ACCOUNT_MANAGER:
      return { ...state, load: true, error: '' };
    case accountManagerTypes.UPDATE_ACCOUNT_MANAGER_FAIL:
      return { ...state, load: false, error: action.payload };

    case accountManagerTypes.OPEN_MODAL:
      return { ...state, isModalOpen: true };
    case accountManagerTypes.CLOSE_MODAL:
      return { ...state, isModalOpen: false };

    case accountManagerTypes.ENABLE_OTHER_ACCOUNT_EDIT:
      return { ...state, otherAccountIsEditable: true };
    case accountManagerTypes.DISABLE_OTHER_ACCOUNT_EDIT:
      return { ...state, otherAccountIsEditable: false };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
