import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';

const CopyText = (props) => {
  const { text } = props;

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const getActiveClass = () => {
    if (copied) {
      return 'active';
    }
    return null;
  };

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <CopyOutlined className={getActiveClass()} />
    </CopyToClipboard>
  );
};
export { CopyText };
