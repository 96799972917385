import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { CookiesProvider } from 'react-cookie';

import Header from './Header';
import SideNav from './SideNav';
import leafPng from '../../assets/images/leaf.png';
import familyPng from '../../assets/images/family.png';
import Logo from '../../assets/images/logo.svg';
import { Alert, ConfirmAlert } from '../../components/common';
import { CONFIRM_ALERT } from '../../store/types/common';
import Aux from '../Aux/Aux';
import * as authActions from '../../store/actions/auth';
import CookiesContainer from '../../containers/auth/CookiesContainer';

// import '../../styles/dashboard.scss';
// import './Layout.scss';

const Layout = ({ props, children }) => {
	let isMobile = false;
	let closeMenu = false;
	if (window.innerWidth < 768) {
		// Extra Small Device
		isMobile = true;
	} else if (window.innerWidth < 991) {
		// Small Device
		isMobile = true;
	} else if (window.innerWidth < 1199) {
		// Medium Device
		isMobile = false;
		closeMenu = true;
	} else {
		// Large Device
		isMobile = false;
		closeMenu = false;
	}

	const navigationUnblockHandle = useRef();

	const [collapsed, setcollapsed] = useState(isMobile || closeMenu ? true : false);
	const [alert, setalert] = useState(props.alert);
	const [showConfirmAlert, setshowConfirmAlert] = useState({
		show: false,
		title: '',
		type: CONFIRM_ALERT.WARNING,
		onConfirm: () => {},
	});

	useEffect(() => {
		setalert(props.alert);
	}, [props.alert]);

	useEffect(() => {
		if (props.sessionExpire) {
			setshowConfirmAlert({
				show: true,
				type: CONFIRM_ALERT.WARNING,
				title: 'Warning',
				text: 'Your session is expired! Please sign in',
				showCancelButton: false,
				onConfirm: () => {
					setshowConfirmAlert({
						show: false,
						title: '',
						type: CONFIRM_ALERT.WARNING,
						onConfirm: () => {},
					});
					props.clearSessions();
					if (!props.isAuthenticated) {
						props.history.push('/login');
						window.location.reload();
					}
				},
			});
		} else {
			navigationUnblockHandle.current = props.history.block((targetLocation) => {
				if (props.location.pathname === '/create-will' && props.willId) {
					if (props.user.showWillAccessibleMessage) {
						setshowConfirmAlert({
							show: true,
							type: CONFIRM_ALERT.INFO,
							title: 'Information',
							text:
								"You will be navigated out of the will creation wizard. You can access this will in the 'Mange Will' section. Do you want to proceed?",
							showCancelButton: true,
							onConfirm: () => {
								setshowConfirmAlert({
									show: false,
									title: '',
									type: CONFIRM_ALERT.INFO,
									onConfirm: () => {},
								});
								if (navigationUnblockHandle) {
									navigationUnblockHandle.current();
								}
								props.history.push(targetLocation);
								props.resetWillCreation();
								props.submitPersonalInfo(
									{
										showWillAccessibleMessage: false,
									},
									false
								);
							},
							onCancel: () => {
								setshowConfirmAlert({
									show: false,
									title: '',
									type: CONFIRM_ALERT.INFO,
									onConfirm: () => {},
								});
							},
						});
						return false;
					}
					props.resetWillCreation();
				}
				return true;
			});
		}
		return () => {
			navigationUnblockHandle.current && navigationUnblockHandle.current();
		};
	}, [props.sessionExpire, props.location.pathname, props.willId, props.willStage]);

	const toggleCollapsed = () => {
		setcollapsed(!collapsed);
	};

	const customNavigate = (toPage) => {
		props.history.push(toPage);
	};

	const getContent = () => {
		if (props.isAuthenticated) {
			if (props.showLayout) {
				let mainPanel = classNames('mainPanel', collapsed ? 'main-panel-close' : 'main-panel-open');
				return (
					<div className="dashboardWrapper">
						<CookiesProvider>
							<CookiesContainer
								signedCookies={props.signedCookies}
								cookieExpireTime={props.cookieExpireTime}
								getSignedCookie={props.getSignedCookie}
							/>
						</CookiesProvider>
						<ConfirmAlert {...showConfirmAlert} />
						{/* {alert.show && typeof alert.message === 'string' && (
              <Alert
                notificationType={alert.type}
                notification={alert.message}
              />
            )} */}
						<Header {...props} toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
						<div className="app">
							<SideNav
								collapsed={collapsed}
								history={props.history}
								toggleCollapsed={toggleCollapsed}
								location={props.location.pathname}
								customNavigate={customNavigate}
							/>
							<div className={mainPanel}>{children}</div>
						</div>
					</div>
				);
			}
			return <>{children}</>;
		}
		if (props.showLayout) {
			return (
				<div className="container landingWrapper">
					<ConfirmAlert {...showConfirmAlert} />
					{/* {alert.show && typeof alert.message === 'string' && (
            <Alert notificationType={alert.type} notification={alert.message} />
          )} */}
					<div className="landingLeft">
						<div className="imageWrapper">
							<div className="image narrowImage">
								<img src={leafPng} alt="leaf" />
							</div>
							<div className="image wideImage">
								<img src={familyPng} alt="family" />
							</div>
							<div className="shape circleLeft" />
							<div className="shape squareBottom" />
							<div className="shape squareRight" />
						</div>
					</div>
					<div className="landingRight">
						<div className="shape circleLeft" />
						<div className="shape squareBottom" />
						<div className="shape squareRight" />
						<div className="landingBackground">
							<div className="authForm">
								<div className="logoSection">
									<img src={Logo} alt="company logo" />
									<div className="tagLine">Staying one step ahead of tomorrow</div>
								</div>
								<div>{children}</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		return <>{children}</>;
	};

	return (
		<>
			{alert.show && typeof alert.message === 'string' && (
				<Alert notificationType={alert.type} notification={alert.message} />
			)}
			{getContent()}
		</>
	);
};

export default Layout;
