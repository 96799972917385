import funeralPlansTypes from '../types/funeralPlansStates';
import commonTypes from '../types/common';

export const initialState = {
  funeralDetails: {
    burialType: 0,
    funeralParlour: '',
    burialGrounds: '',
    rightsAndRemarks: '',
    gracePeriod: '',
  },
  videoLogs: [],
  videos: [],
  audios: [],
  invitees: [],
  error: '',
  funeralPlanId: null,
  load: false,
  searchTerm: '',
};

export const funeralPlans = (state = initialState, action) => {
  switch (action.type) {
    case funeralPlansTypes.FUNERAL_PLANS_SUCCESS:
      return {
        ...state,
        load: false,
        funeralPlanId: action.payload._id,
        funeralDetails: {
          burialType: action.payload.burialType,
          funeralParlour: action.payload.funeralParlour,
          burialGrounds: action.payload.burialGrounds,
          rightsAndRemarks: action.payload.rightsAndRemarks,
          gracePeriod: action.payload.gracePeriod,
        },
        videos: action.payload.videos,
        audios: action.payload.audios,
        invitees: action.payload.invitees,
        error: '',
      };

    case funeralPlansTypes.GET_FUNERAL_PLANS:
      return { ...state, load: true, error: '' };

    case funeralPlansTypes.GET_FUNERAL_PLANS_FAIL:
      return { ...state, error: action.payload };

    case funeralPlansTypes.GET_FUNERAL_PLANS_VIDEO_LOGS_SUCCESS:
      return { ...state, videoLogs: action.payload, load: false, error: '' };

    case funeralPlansTypes.GET_FUNERAL_PLANS_VIDEO_LOGS_FAIL:
      return { ...state, error: action.payload };

    case funeralPlansTypes.ADD_FUNERAL_PLANS:
      return { ...state, load: true, error: '' };
    case funeralPlansTypes.ADD_FUNERAL_PLANS_FAIL:
      return { ...state, error: action.payload };

    case funeralPlansTypes.UPDATE_FUNERAL_PLANS:
      return { ...state, load: true, error: '' };
    case funeralPlansTypes.UPDATE_FUNERAL_PLANS_FAIL:
      return { ...state, error: action.payload };

    case funeralPlansTypes.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
