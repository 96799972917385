module.exports = {
  GET_SUMMARY_PAGE_UPLOAD_EXISTING_WILL_DOCUMENT_SUCCESS:
    'GET_SUMMARY_PAGE_UPLOAD_EXISTING_WILL_DOCUMENT_SUCCESS',
  GET_SUMMARY_PAGE_UPLOAD_EXISTING_WILL_DOCUMENT_FAIL:
    'GET_SUMMARY_PAGE_UPLOAD_EXISTING_WILL_DOCUMENT_FAIL',

  GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_SUCCESS:
    'GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_SUCCESS',
  GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_FAIL:
    'GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_FAIL',
  GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_PRESIGNED_URL_SUCCESS:
    'GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_PRESIGNED_URL_SUCCESS',
  GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_PRESIGNED_URL_FAIL:
    'GET_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_PRESIGNED_URL_FAIL',
  CLEAR_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_DATA:
    'CLEAR_SUMMARY_PAGE_UPLOADED_SIGNED_WILL_DATA',

  GET_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS:
    'GET_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS',
  GET_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_FAIL:
    'GET_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_FAIL',
  UPDATE_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS:
    'UPDATE_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_SUCCESS',
  UPDATE_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_FAIL:
    'UPDATE_SUMMARY_PAGE_GENERATED_WILL_DOCUMENT_FAIL',

  UPDATE_SUMMARY_PAGE_OTHER_DETAILS_SUCCESS:
    'UPDATE_SUMMARY_PAGE_OTHER_DETAILS_SUCCESS',
  UPDATE_SUMMARY_PAGE_OTHER_DETAILS_FAIL:
    'UPDATE_SUMMARY_PAGE_OTHER_DETAILS_FAIL',

  GET_SUMMARY_PAGE_INFORMATION_SUCCESS: 'GET_SUMMARY_PAGE_INFORMATION_SUCCESS',
  GET_SUMMARY_PAGE_INFORMATION_FAIL: 'GET_SUMMARY_PAGE_INFORMATION_FAIL',

  GET_SUMMARY_PAGE_ATTACHMENTS_SUCCESS: 'GET_SUMMARY_PAGE_ATTACHMENTS_SUCCESS',
  GET_SUMMARY_PAGE_ATTACHMENTS_FAIL: 'GET_SUMMARY_PAGE_ATTACHMENTS_FAIL',

  ADD_WILL_ATTACHMENT_SUCCESS: 'ADD_WILL_ATTACHMENT_SUCCESS',
  ADD_WILL_ATTACHMENT_FAIL: 'ADD_WILL_ATTACHMENT_FAIL',

  WILL_ATTACHMENT_UPLOADING_START: 'WILL_ATTACHMENT_UPLOADING_START',
  WILL_ATTACHMENT_UPLOADING_PROGRESS: 'WILL_ATTACHMENT_UPLOADING_PROGRESS',
  WILL_ATTACHMENT_UPLOADING_SUCCESS: 'WILL_ATTACHMENT_UPLOADING_SUCCESS',
  WILL_ATTACHMENT_UPLOADING_FAIL: 'WILL_ATTACHMENT_UPLOADING_FAIL',
  WILL_ATTACHMENT_UPLOADING_CANCEL_BY_USER:
    'WILL_ATTACHMENT_UPLOADING_CANCEL_BY_USER',

  WILL_DOCUMENTS_DOWNLOAD_SUCCESS: 'WILL_DOCUMENTS_DOWNLOAD_SUCCESS',
  WILL_DOCUMENTS_DOWNLOAD_FAIL: 'WILL_DOCUMENTS_DOWNLOAD_FAIL',

  WILL_ATTACHMENT_DOWNLOAD_SUCCESS: 'WILL_ATTACHMENT_DOWNLOAD_SUCCESS',
  WILL_ATTACHMENT_DOWNLOAD_FAIL: 'WILL_ATTACHMENT_DOWNLOAD_FAIL',

  DELETE_WILL_ATTACHMENT_SUCCESS: 'DELETE_WILL_ATTACHMENT_SUCCESS',
  DELETE_WILL_ATTACHMENT_FAIL: 'DELETE_WILL_ATTACHMENT_FAIL',

  WILL_ATTACHMENT_OPEN_MODAL: 'WILL_ATTACHMENT_OPEN_MODAL',
  WILL_ATTACHMENT_CLOSE_MODAL: 'WILL_ATTACHMENT_CLOSE_MODAL',

  WILL_GENERATE_OPEN_MODAL: 'WILL_GENERATE_OPEN_MODAL',
  WILL_GENERATE_CLOSE_MODAL: 'WILL_GENERATE_CLOSE_MODAL',

  RESET_WILL_SUMMARY_PAGE: 'RESET_WILL_SUMMARY_PAGE',
};
