const API = {
	PROTOCOL: process.env.REACT_APP_PROTOCOL,
	HOST: process.env.REACT_APP_HOST,
	VERSION: process.env.REACT_APP_API_VERSION,
	PORT: process.env.REACT_APP_PORT,
};
const FIREBASE_AUTH_URL = process.env.REACT_APP_FIREBASE_AUTH_URL;
const BASE_URL = API.PROTOCOL + '://' + API.HOST; // Server
//const BASE_URL = API.PROTOCOL + '://' + API.HOST + ':' +API.PORT; // local

const PATHS = {
	COMMON_API: '/v1/',
	USER_API: '/v1/user-management/',
	UTIL_API: '/v1/util/',
	WILL_API: '/v1/will-management/',
};

module.exports = {
	ROUTES: {
		SIGNUP: BASE_URL + PATHS.COMMON_API + 'signup',
		LOGIN: BASE_URL + PATHS.COMMON_API + 'signin',
		VERIFY: BASE_URL + PATHS.COMMON_API + 'verify-email',
		RESEND_EMAIL: BASE_URL + PATHS.COMMON_API + 'resend-verify-email',
		FORGOT_PASSWORD: BASE_URL + PATHS.COMMON_API + 'forgot-password',
		ADD_LAW_FIRM: BASE_URL + PATHS.COMMON_API + 'register-law-firm',
		GET_LAW_FIRMS: BASE_URL + PATHS.COMMON_API + 'law-firms',
		ADD_LAWYER: BASE_URL + PATHS.COMMON_API + 'create-lawyer-account',
		GET_COUNTRIES: BASE_URL + PATHS.COMMON_API + 'countries',
		INVITE_LAWYERS: BASE_URL + PATHS.COMMON_API + 'send-emails',

		MEDIA: BASE_URL + PATHS.USER_API + 'media',

		PERSIGNED_URL: BASE_URL + PATHS.USER_API + 'pre-singed-url',
		UPDATE_USER_PROFILE: BASE_URL + PATHS.USER_API + 'profile',

		LIFESTORIES: BASE_URL + PATHS.UTIL_API + 'life-stories',
		FUNERALPLANS: BASE_URL + PATHS.UTIL_API + 'funeral-plans',
		ACCOUNTMANAGER: BASE_URL + PATHS.UTIL_API + 'account-manager',
		VIDEOLOGS: BASE_URL + PATHS.UTIL_API + 'video-logs',
		VIDEOLOGSALLOCATEDQUOTA: BASE_URL + PATHS.UTIL_API + 'video-logs-allocations',
		WRITE_LOGS: BASE_URL + PATHS.UTIL_API + 'written-logs',

		WILL: BASE_URL + PATHS.WILL_API + 'will',
		WILL_ATTACHMENTS: BASE_URL + PATHS.WILL_API + 'attachments',
		WILL_GENERATE: BASE_URL + PATHS.WILL_API + 'generate',

		WILL_PROBATE: BASE_URL + PATHS.WILL_API + 'probate',
		EXTERNAL_LOGS: BASE_URL + PATHS.UTIL_API + 'external-logs',

		// dashboard
		DASHBOARD: BASE_URL + PATHS.WILL_API + 'dashboard/primary-will',
		WILL_CHECK_LIST: BASE_URL + PATHS.WILL_API + 'dashboard/check-list',
		CHANGE_LOGS: BASE_URL + PATHS.WILL_API + 'dashboard/change-logs',

		MY_DEATH_REQUEST: BASE_URL + PATHS.WILL_API + 'death-requests',
		WILL_CREATION_CHANGE_LOGS: BASE_URL + PATHS.WILL_API + 'will/change-logs',

		FIREBASE_AUTHENTICATION_URL: BASE_URL + PATHS.COMMON_API + 'firebase-auth',
	},
};
