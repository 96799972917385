import React, { Suspense, PureComponent } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import routes from './routes';
import Layout from './hoc/Layout/Layout';
import { Loading } from './components/common';
import SplashScreen from './components/splashScreen';
import Aux from './hoc/Aux/Aux';
import { authUser } from './libs/validation';
import AuthenticatedRoute from './containers/routes/AuthenticatedRoute';
import UnauthenticatedRoute from './containers/routes/UnauthenticatedRoute';
import * as commonActions from './store/actions/common';
import * as authActions from './store/actions/auth';
import * as createWillCommonActions from './store/actions/createWill/common';
import * as settingActions from './store/actions/settings';
import { responceMessage, sessionExpire } from './store/selectors/common';
import {
  user,
  userRegistrationStep,
  signedCookies,
  cookieExpireTime,
} from './store/selectors/auth';
import { willId, willStage } from './store/selectors/createWill/common';

// import 'bootstrap/dist/css/bootstrap.css'; Need to check with QA and Develop branches
import './styles/main.scss';

class App extends PureComponent {
  state = {
    isAuthenticated: false,
    location: {},
    welcome: false,
    previousLocationPath: '',
    responceMessage: this.props.responceMessage || {},
    sessionExpire: false,
    willId: null,
    willStage: null,
    userRegistrationStep: null,
    showLayout: true,
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let authorizedRoute = false;
    let showLayout = true;
    if (
      authUser() &&
      nextProps.location.pathname !== '/verify' &&
      nextProps.location.pathname !== '/registration' &&
      nextProps.location.pathname !== '/login' &&
      nextProps.location.pathname !== '/lawyer-register'
    ) {
      authorizedRoute = true;
    }

    if (nextProps.location.pathname == '/welcome') {
      return {
        welcome: true,
        responceMessage: nextProps.responceMessage,
        userRegistrationStep: nextProps.userRegistrationStep,
      };
    }

    if (nextProps.location.pathname.includes('/external')) {
      showLayout = false;
    }

    if (
      (prevState.location !== nextProps.location &&
        nextProps.location.pathname !== '/welcome') ||
      nextProps.responceMessage !== prevState.responceMessage ||
      nextProps.sessionExpire !== prevState.sessionExpire ||
      nextProps.willId !== prevState.willId ||
      nextProps.willStage !== prevState.willStage ||
      nextProps.userRegistrationStep !== prevState.userRegistrationStep
    ) {
      return {
        location: nextProps.location,
        isAuthenticated: authorizedRoute,
        welcome: false,
        previousLocationPath: prevState.location.pathname,
        responceMessage: nextProps.responceMessage,
        sessionExpire: nextProps.sessionExpire,
        userRegistrationStep: nextProps.userRegistrationStep,
        willId: nextProps.willId,
        willStage: nextProps.willStage,
        showLayout,
      };
    }

    return null;
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if(this.state.location !== prevState.location) {
    //   this.props.clearErrors();
    // }
  };

  render() {
    const {
      welcome,
      isAuthenticated,
      previousLocationPath,
      responceMessage,
      sessionExpire,
      userRegistrationStep,
      showLayout,
    } = this.state;

    const childProps = {
      ...this.props,
      isAuthenticated,
      previousLocationPath,
      userRegistrationStep,
      alert: responceMessage,
      sessionExpire,
      showLayout,
    };

    const pages = (
      <Switch>
        {routes.map((route, idx) => {
          return route.component ? (
            route.authorizedRoute ? (
              <AuthenticatedRoute
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                props={childProps}
                component={route.component}
              />
            ) : (
              <UnauthenticatedRoute
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                props={childProps}
                component={route.component}
              />
            )
          ) : null;
        })}
        <Redirect to="/login" />
      </Switch>
    );

    return (
      <Aux>
        {welcome && <SplashScreen {...childProps} />}
        {!welcome && (
          <Layout props={childProps}>
            <Suspense fallback={<Loading />}>{pages}</Suspense>
          </Layout>
        )}
      </Aux>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = () =>
  createStructuredSelector({
    responceMessage,
    user,
    userRegistrationStep,
    sessionExpire,
    willId,
    willStage,
    signedCookies,
    cookieExpireTime,
  });

const mapDispatchToProps = (dispatch) => {
  return {
    clearSessions: () => dispatch(commonActions.sessionLogout()),
    resetWillCreation: () =>
      dispatch(createWillCommonActions.resetWillCreation()),
    logout: () => dispatch(authActions.logout()),
    getSignedCookie: () => dispatch(authActions.getSignedCookie()),
    submitPersonalInfo: (data, showResponseMsg) =>
      dispatch(settingActions.submitPersonalInfo(data, showResponseMsg)),
    updateProfilePicInAccountComp: (image) =>
      dispatch(settingActions.updateProfilePicInAccountComp(image)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
