import React from 'react';
import { Menu } from 'antd';
import {
  ChromeOutlined,
  MessageOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  FileOutlined,
  FileTextOutlined,
  SettingOutlined,
  UserOutlined,
  LoginOutlined,
  FilePptOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import classNames from 'classnames';

const SideNav = (props) => {
  let isMobile = false;
  let closeMenu = false;
  if (window.innerWidth < 768) {
    // Extra Small Device
    isMobile = true;
  } else if (window.innerWidth < 850) {
    // Small Device
    isMobile = true;
  } else if (window.innerWidth < 1199) {
    // Medium Device
    isMobile = false;
    closeMenu = true;
  } else {
    // Large Device
    isMobile = false;
    closeMenu = false;
  }

  const { SubMenu } = Menu;

  const goTo = (page) => {
    console.log('goTo', page);
    if (isMobile) props.toggleCollapsed();
    else if (closeMenu && !props.collapsed) props.toggleCollapsed();

    props.customNavigate(page);
  };

  let sideBar = classNames(
    'sideBar',
    isMobile
      ? props.collapsed
        ? ''
        : 'sideBaropen'
      : props.collapsed
      ? 'sideBarclose'
      : 'sideBaropen'
  );

  return (
    <Menu
      defaultSelectedKeys={[props.location]}
      selectedKeys={[props.location]}
      mode="inline"
      className={sideBar}
      inlineCollapsed={isMobile ? false : props.collapsed}
      triggerSubMenuAction="hover"
    >
      <Menu.Item
        key="/dashboard"
        onClick={() => goTo('/dashboard')}
        icon={<HomeOutlined />}
      >
        Dashboard
      </Menu.Item>
      <SubMenu key="sub1" icon={<FileOutlined />} title="My Will">
        <Menu.Item key="/create-will" onClick={() => goTo('/create-will')}>
          Create Will
        </Menu.Item>
        <Menu.Item key="/manage-will" onClick={() => goTo('/manage-will')}>
          Manage Will
        </Menu.Item>
        <Menu.Item key="/funeral-plans" onClick={() => goTo('/funeral-plans')}>
          Funeral Plans
        </Menu.Item>
      </SubMenu>
      <Menu.Item
        key="/probate-will"
        onClick={() => goTo('/probate-will')}
        icon={<FilePptOutlined />}
      >
        Probate Will
      </Menu.Item>
      <Menu.Item key="6" icon={<FileTextOutlined />}>
        Notorize Will <span>Coming soon</span>
      </Menu.Item>
      <div className="dropdown-divider" role="separator"></div>
      <Menu.Item
        key="/life-story"
        onClick={() => goTo('/life-story')}
        icon={<ChromeOutlined />}
      >
        My Life Story
      </Menu.Item>
      <Menu.Item
        key="/my-logs"
        onClick={() => goTo('/my-logs')}
        icon={<VideoCameraOutlined />}
      >
        My Logs
      </Menu.Item>
      <Menu.Item
        key="/account-manager"
        onClick={() => goTo('/account-manager')}
        icon={<LoginOutlined />}
      >
        Account Manager
      </Menu.Item>
      <div className="dropdown-divider" role="separator"></div>
      <Menu.Item key="11" icon={<UserOutlined />}>
        Find a Lawyer <span>Coming soon</span>
      </Menu.Item>
      <Menu.Item key="9" icon={<MessageOutlined />}>
        Messages <span>Coming soon</span>
      </Menu.Item>
      {/* <Menu.Item key="12" icon={<SettingOutlined />}>
        Settings
      </Menu.Item> */}
    </Menu>
  );
};

export default SideNav;
