import guardiansTypes from '../../types/createWill/guardians';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  guardianInformation: [],
  errorIDs: [],
  guardianRemarks: '',
};

export const guardians = (state = initialState, action) => {
  switch (action.type) {
      case guardiansTypes.GET_GUARDIANS_PAGE_SUCCESS:
          return {
              ...state,
              guardianInformation: action.payload.guardianInformation,
              guardianRemarks: action.payload.guardianRemarks,
              errorIDs: [],
          };
      case guardiansTypes.GET_GUARDIANS_PAGE_FAIL:
          return { ...state, error: action.payload, errorIDs: [] };

      case guardiansTypes.UPDATE_GUARDIANS_PAGE_SUCCESS:
          return {
              ...state,
              guardianInformation: action.payload.guardianInformation,
              guardianRemarks: action.payload.guardianRemarks,
              errorIDs: [],
          };
      case guardiansTypes.UPDATE_GUARDIANS_PAGE_FAIL:
          return {
              ...state,
              error: action.payload.error,
              guardianInformation: action.payload.guardianInformation,
              errorIDs: action.payload.errorIDs,
          };

      case guardiansTypes.RESET_WILL_GUARDIANS_PAGE:
          return { ...initialState };

      case commonTypes.CLEAR_STORAGE:
          return { ...initialState };

      default:
          return state;
  }
};
