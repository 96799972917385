module.exports = {
  UPDATE_SETTINGS_PAGE_PERSONAL_INFO_SUCCESS:
    'UPDATE_SETTINGS_PAGE_PERSONAL_INFO_SUCCESS',
  UPDATE_SETTINGS_PAGE_PERSONAL_INFO_FAIL:
    'UPDATE_SETTINGS_PAGE_PERSONAL_INFO_FAIL',

  UPDATE_SETTINGS_PAGE_SECURITY_INFO_SUCCESS:
    'UPDATE_SETTINGS_PAGE_SECURITY_INFO_SUCCESS',
  UPDATE_SETTINGS_PAGE_SECURITY_INFO_FAIL:
    'UPDATE_SETTINGS_PAGE_SECURITY_INFO_FAIL',

  UPDATE_SETTINGS_PAGE_PERSONAL_INFO_EDITABLE_STATE:
    'UPDATE_SETTINGS_PAGE_PERSONAL_INFO_EDITABLE_STATE',
  UPDATE_SETTINGS_PAGE_SECURITY_INFO_EDITABLE_STATE:
    'UPDATE_SETTINGS_PAGE_SECURITY_INFO_EDITABLE_STATE',

  UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE:
    'UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE',
  UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_SUCCESS:
    'UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_SUCCESS',
  UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_FAIL:
    'UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_FAIL',
  
  UPDATE_NEWLY_ADDED_PROFILE_PIC_IN_ACCOUNT: 'UPDATE_NEWLY_ADDED_PROFILE_PIC_IN_ACCOUNT',

  RESET_SETTING_PAGE: 'RESET_SETTING_PAGE',
};
