module.exports = {
  GET_EXECUTORS_PAGE_SUCCESS: 'GET_EXECUTORS_PAGE_SUCCESS',
  GET_EXECUTORS_PAGE_FAIL: 'GET_EXECUTORS_PAGE_FAIL',

  UPDATE_EXECUTORS_PAGE_SUCCESS: 'UPDATE_EXECUTORS_PAGE_SUCCESS',
  UPDATE_EXECUTORS_PAGE_FAIL: 'UPDATE_EXECUTORS_PAGE_FAIL',

  SET_EXECUTORS_EDIT_ITEM: 'SET_EXECUTORS_EDIT_ITEM',

  SET_EXECUTORS_DATA: 'SET_EXECUTORS_DATA',
  SET_EXECUTORS_OTHER_DATA: 'SET_EXECUTORS_OTHER_DATA',

  RESET_WILL_EXECUTORS_PAGE: 'RESET_WILL_EXECUTORS_PAGE',
};
