import React, { useState, useEffect, useRef } from 'react';
import { Route, Redirect } from 'react-router-dom';

export default ({ component: Component, props: cProps, ...rest }) => {
  const [unAuthorizedRoute] = useState([
    '/login',
    '/registration',
    '/verify',
    '/lawyer-register',
    '/forgotPassword',
  ]);

  useEffect(() => {
    if (
      !cProps.isAuthenticated &&
      !unAuthorizedRoute.includes(cProps.location.pathname)
    )
      window.location.reload();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        cProps.isAuthenticated ? (
          <Component {...props} {...cProps} />
        ) : (
          <Redirect to={'/login'} />
        )
      }
    />
  );
};
