/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import moment from 'moment';

const CookiesContainer = (props) => {
  const { REACT_APP_MEDIA_SUB_DOMAIN: MEDIA_DOMAIN } = process.env;
  const { getSignedCookie } = props;

  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [signedCookies, setSignedCookies] = useState(null);
  const [cookieExpireTime, setCookieExpireTime] = useState(null);
  const [isExpiredCookies, setIsExpiredCookies] = useState(false);

  useEffect(() => {
    if (signedCookies !== props.signedCookies) {
      setSignedCookies(props.signedCookies);
    }
  }, [props.signedCookies]);

  useEffect(() => {
    if (cookieExpireTime !== props.cookieExpireTime) {
      setCookieExpireTime(props.cookieExpireTime);
    }
  }, [props.cookieExpireTime]);

  useEffect(() => {
    if (signedCookies) {
      setCookie(
        signedCookies.cloudFrontPolicyKey,
        signedCookies.cloudFrontPolicyValue,
        // { domain: DOMAIN, path: '/' }
        { domain: MEDIA_DOMAIN }
        // { path: '/' },
        // { secure: true, sameSite: 'none' }
      );
      setCookie(
        signedCookies.cloudFrontSignatureKey,
        signedCookies.cloudFrontSignatureValue,
        // { domain: DOMAIN, path: '/' }
        { domain: MEDIA_DOMAIN }
        // { path: '/' },
        // { secure: true, sameSite: 'none' }
      );
      setCookie(
        signedCookies.cloudFrontKeyPairIdKey,
        signedCookies.cloudFrontKeyPairIdValue,
        // { domain: DOMAIN, path: '/' }
        { domain: MEDIA_DOMAIN }
        // { path: '/' },
        // { secure: true, sameSite: 'none' }
      );
    }

    return () => {
      if (signedCookies) {
        removeCookie(signedCookies.cloudFrontPolicyKey, {
          domain: MEDIA_DOMAIN,
        });
        removeCookie(signedCookies.cloudFrontSignatureKey, {
          domain: MEDIA_DOMAIN,
        });
        removeCookie(signedCookies.cloudFrontKeyPairIdKey, {
          domain: MEDIA_DOMAIN,
        });
      }
    };
  }, [signedCookies]);

  useEffect(() => {
    if (isExpiredCookies) {
      getSignedCookie();
      setIsExpiredCookies(false);
    }
  }, [isExpiredCookies]);

  const checkCookieExpired = () => {
    if (cookieExpireTime) {
      const now = moment();
      const expireDatetime = moment.unix(cookieExpireTime / 1000);
      // const expireDatetime = moment.unix((cookieExpireTime - 10364068) / 1000);
      // console.log('checkCookieExpired', now, expireDatetime, cookieExpireTime);
      if (expireDatetime.isBefore(now) && !isExpiredCookies) {
        setIsExpiredCookies(true);
      }
    }
  };

  return <>{checkCookieExpired()}</>;
};
export default CookiesContainer;
