import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import dashboardTypes from '../types/dashboardStates';

export const initialState = {
	dashboardData: {},
	willCheckListData: {},
	revisionLogs: {},
	isLoading: false,
	isCheckListDataLoading: false,
	isLogsLoading: false,
	deathRequest: null,
	isOpenDeathRequestModal: false,
};

export const dashboard = (state = initialState, action) => {
	switch (action.type) {
    case dashboardTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case dashboardTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload,
      };
    case dashboardTypes.GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        dashboardData: {},
      };

    case dashboardTypes.GET_CHECKLIST_DATA:
      return {
        ...state,
        isCheckListDataLoading: true,
      };
    case dashboardTypes.GET_CHECKLIST_DATA_SUCCESS:
      return {
        ...state,
        isCheckListDataLoading: false,
        willCheckListData: action.payload,
      };
    case dashboardTypes.GET_CHECKLIST_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        willCheckListData: {},
      };

    case dashboardTypes.GET_REVISION_LOGS:
      return {
        ...state,
        isLogsLoading: true,
      };
    case dashboardTypes.GET_REVISION_LOGS_SUCCESS:
      const { changeLogs, pagination } = action.payload;
      const data = cloneDeep(state);
      if (pagination.page == 1) {
        data.revisionLogs.pagination = pagination;
        data.revisionLogs.data = changeLogs;
      } else {
        data.revisionLogs.pagination = pagination;
        data.revisionLogs.data = [...data.revisionLogs, ...changeLogs];
      }
      data.isLogsLoading = false;
      return { ...data };
    case dashboardTypes.GET_REVISION_LOGS_FAIL:
      return {
        ...state,
        isLogsLoading: false,
      };

    case dashboardTypes.GET_MY_ACTIVE_DEATH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dashboardTypes.GET_MY_ACTIVE_DEATH_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deathRequest: action.payload,
      };
    case dashboardTypes.GET_MY_ACTIVE_DEATH_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        deathRequest: null,
      };

    case dashboardTypes.REJECT_MY_DEATH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dashboardTypes.REJECT_MY_DEATH_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deathRequest: action.payload,
      };
    case dashboardTypes.REJECT_MY_DEATH_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case dashboardTypes.DEATH_REQUEST_MODAL_STATUS:
      return {
        ...state,
        isOpenDeathRequestModal: action.payload,
      };

    default:
      return state;
  }
};
