import React from 'react';
import { Avatar } from 'antd';

const ProfileImage = (props) => {
	const { icon, src, className, size = 45, type } = props;

	return (
		<Avatar
			size={type === 'acknowledgeModal' || type === 'notificationImage' ? 45 : size}
			src={src}
			icon={icon}
			className={className}
		/>
	);
};
export default ProfileImage;
