module.exports = {
  PROBATE_GET_FUNERAL_PLAN_SUCCESS: 'PROBATE_GET_FUNERAL_PLAN_SUCCESS',
  PROBATE_GET_FUNERAL_PLAN_FAIL: 'PROBATE_GET_FUNERAL_PLAN_FAIL',

  PROBATE_UPDATE_FUNERAL_PLAN_INVITATION_MSG_SUCCESS:
    'PROBATE_UPDATE_FUNERAL_PLAN_INVITATION_MSG_SUCCESS',
  PROBATE_UPDATE_FUNERAL_PLAN_INVITATION_MSG_FAIL:
    'PROBATE_UPDATE_FUNERAL_PLAN_INVITATION_MSG_FAIL',

  PROBATE_SEND_FUNERAL_PLAN_INVITATIONS_SUCCESS:
    'PROBATE_SEND_FUNERAL_PLAN_INVITATIONS_SUCCESS',
  PROBATE_SEND_FUNERAL_PLAN_INVITATIONS_FAIL:
    'PROBATE_SEND_FUNERAL_PLAN_INVITATIONS_FAIL',
};
