import { createSelector } from 'reselect';

const createWillCommonDomain = ({ createWill }) => createWill.common;

const currentStage = createSelector([createWillCommonDomain], (createWillCommonState) => {
	if (createWillCommonState.currentStage) {
		return createWillCommonState.currentStage;
	}
	return 0;
});

const willId = createSelector([createWillCommonDomain], (createWillCommonState) => {
	if (createWillCommonState.willId) {
		return createWillCommonState.willId;
	}
	return null;
});

const willStage = createSelector([createWillCommonDomain], (createWillCommonState) => {
	if (createWillCommonState.willStage) {
		return createWillCommonState.willStage;
	}
	return 'draft';
});

const loading = createSelector([createWillCommonDomain], (createWillCommonState) => {
	if (createWillCommonState.loading) {
		return createWillCommonState.loading;
	}
	return false;
});

const countries = createSelector([createWillCommonDomain], (createWillCommonState) => {
	if (
		createWillCommonState.countriesAndStates &&
		createWillCommonState.countriesAndStates.length > 0
	) {
		return createWillCommonState.countriesAndStates.map((item) => ({
			label: item.name,
			value: item.name,
		}));
	}
	return [];
});

const states = createSelector([createWillCommonDomain], (createWillCommonState) => {
	if (
		createWillCommonState.countriesAndStates &&
		createWillCommonState.countriesAndStates.length > 0
	) {
		let statesWithCountry = {};
		createWillCommonState.countriesAndStates.forEach((item) => {
			statesWithCountry[item.name] = item.states.map((i) => ({
				label: i,
				value: i,
			}));
		});
		return statesWithCountry;
	}
	return {};
});

const isAcknowledgeModalOpen = createSelector([createWillCommonDomain], (createWillCommonState) => {
	return createWillCommonState.isAcknowledgeModalOpen;
});

const jointWillStatus = createSelector([createWillCommonDomain], (createWillCommonState) => {
	return createWillCommonState.jointWillStatus;
});

const isOwner = createSelector([createWillCommonDomain], (createWillCommonState) => {
	return createWillCommonState.isOwner;
});

const acknowledgeChanges = createSelector([createWillCommonDomain], (createWillCommonState) => {
	return createWillCommonState.acknowledgeChanges;
});

export {
	currentStage,
	loading,
	willId,
	willStage,
	countries,
	states,
	isAcknowledgeModalOpen,
	jointWillStatus,
	isOwner,
	acknowledgeChanges
};
