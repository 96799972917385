import probateWillCommonTypes from '../../types/probateWill/common';
import commonTypes from '../../types/common';

export const initialState = {
  loading: false,
  error: '',
  willRequests: [],
  deathRequests: [],
  selectedWillRequest: null,
  selectedUserId: null,
  activeUserType: 'owner',
  executor: null,
  isOpenInformDeathModal: false,
  isUploading: false,
  uploadingProgress: 0,
};

export const common = (state = initialState, action) => {
  switch (action.type) {
    case probateWillCommonTypes.PROBATE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };


    case probateWillCommonTypes.PROBATE_GET_MY_WILLS_SUCCESS:
      return {
        ...state,
        willRequests: action.payload,
      };
    case probateWillCommonTypes.PROBATE_GET_MY_WILLS_FAIL:
      return { ...state, error: action.payload };


    case probateWillCommonTypes.PROBATE_GET_DEATH_REQUESTS_SUCCESS:
      return {
        ...state,
        deathRequests: action.payload,
      };
    case probateWillCommonTypes.PROBATE_GET_DEATH_REQUESTS_FAIL:
      return { ...state, error: action.payload };


    case probateWillCommonTypes.PROBATE_INFORM_DEATH_SUCCESS:
      return {
        ...state,
      };
    case probateWillCommonTypes.PROBATE_INFORM_DEATH_FAIL:
      return { ...state, error: action.payload };

    case probateWillCommonTypes.PROBATE_DEATH_DOCUMENTS_UPLOADING_START:
      return { ...state, isUploading: true };
    case probateWillCommonTypes.PROBATE_DEATH_DOCUMENTS_UPLOADING_PROGRESS:
      return { ...state, uploadingProgress: action.payload };
    case probateWillCommonTypes.PROBATE_DEATH_DOCUMENTS_UPLOADING_SUCCESS:
      return { ...state, isUploading: false };
    case probateWillCommonTypes.PROBATE_DEATH_DOCUMENTS_UPLOADING_FAIL:
      return { ...state, isUploading: false };
    case probateWillCommonTypes.PROBATE_DEATH_DOCUMENTS_UPLOADING_CANCEL_BY_USER:
      return { ...state, isUploading: false };

    case probateWillCommonTypes.PROBATE_DELETE_DEATH_INFORMED_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case probateWillCommonTypes.PROBATE_DELETE_DEATH_INFORMED_REQUEST_FAIL:
      return { ...state, error: action.payload };


    case probateWillCommonTypes.PROBATE_UPDATE_EXECUTOR_JOINING_STATUS_SUCCESS:
      return {
        ...state,
        willRequests: action.payload.willRequests,
        selectedWillRequest: action.payload.selectedWillRequest,
      };
    case probateWillCommonTypes.PROBATE_UPDATE_EXECUTOR_JOINING_STATUS_FAIL:
      return { ...state, error: action.payload };


    case probateWillCommonTypes.PROBATE_SET_SELECTED_REQUEST:
      return {
        ...state,
        selectedWillRequest: action.payload,
      };
    case probateWillCommonTypes.PROBATE_SET_SELECTED_USER:
      return {
        ...state,
        selectedUserId: action.payload,
      };
    case probateWillCommonTypes.PROBATE_SET_SELECTED_USER_TYPE:
      return {
        ...state,
        activeUserType: action.payload,
      };
    case probateWillCommonTypes.PROBATE_SET_EXECUTOR_DATA:
      return {
        ...state,
        executor: action.payload,
      };


    case probateWillCommonTypes.PROBATE_OPEN_INFORM_DEATH_MODAL:
      return { ...state, isOpenInformDeathModal: true };
    case probateWillCommonTypes.PROBATE_CLOSE_INFORM_DEATH_MODAL:
      return { ...state, isOpenInformDeathModal: false };


    case probateWillCommonTypes.RESET_PROBATE_WILL:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
