import createWillWelcomeTypes from '../../types/createWill/welcome';
import commonTypes from '../../types/common';

export const initialState = {
	error: '',
	willCategory: null,
	willType: null,
	country: null,
	state: null,
	willDocuments: [],
	isUploading: false,
	uploadingProgress: 0,
};

export const welcome = (state = initialState, action) => {
	switch (action.type) {
		case createWillWelcomeTypes.GET_WELCOME_PAGE_SUCCESS:
			return {
				...state,
				willCategory: action.payload.willCategory,
				willType: action.payload.willType,
				country: action.payload.country,
				state: action.payload.state,
				willDocuments: action.payload.willDocuments,
			};
		case createWillWelcomeTypes.GET_WELCOME_PAGE_FAIL:
			return { ...state, error: action.payload };

		case createWillWelcomeTypes.UPDATE_WELCOME_PAGE_SUCCESS:
			return {
				...state,
				willCategory: action.payload.willCategory,
				willType: action.payload.willType,
				country: action.payload.country,
				state: action.payload.state,
				willDocuments: action.payload.willDocuments,
			};
		case createWillWelcomeTypes.UPDATE_WELCOME_PAGE_FAIL:
			return { ...state, error: action.payload };

		case createWillWelcomeTypes.QUICK_UPDATE_WELCOME_PAGE_DATA:
			return {
				...state,
				willCategory: action.payload.willCategory,
			};

		case createWillWelcomeTypes.WILL_DOCUMENT_UPLOADING_START:
			return { ...state, isUploading: true };
		case createWillWelcomeTypes.WILL_DOCUMENT_UPLOADING_PROGRESS:
			return { ...state, uploadingProgress: action.payload };
		case createWillWelcomeTypes.WILL_DOCUMENT_UPLOADING_SUCCESS:
			return { ...state, isUploading: false };
		case createWillWelcomeTypes.WILL_DOCUMENT_UPLOADING_FAIL:
			return { ...state, isUploading: false };
		case createWillWelcomeTypes.WILL_DOCUMENT_UPLOADING_CANCEL_BY_USER:
			return { ...state, isUploading: false };

		case createWillWelcomeTypes.RESET_WILL_WELCOMEPAGE:
			return { ...initialState };

		case createWillWelcomeTypes.UPDATE_WILL_TYPE_AND_CATEGORY:
			return {
				...state,
				willType: action.payload.willType,
				willCategory: action.payload.willCategory,
			};

		case commonTypes.CLEAR_STORAGE:
			return { ...initialState };

		default:
			return state;
	}
};
