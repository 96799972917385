import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import Immutable from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers';

const initialState = Immutable.Map();
const isDev = String(process.env.NODE_ENV).toLowerCase() === 'development';
// const isDev = false

export const middlewares = [
  thunkMiddleware,
  routerMiddleware(),
  ...(isDev ? [] : []),
];

const PERSIST_VERSION = 1;
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  version: PERSIST_VERSION,
  stateReconciler: autoMergeLevel2,
  blacklist: ["dashboard"],
};

const store = createStore(
  persistReducer(rootPersistConfig, rootReducer, initialState),
  isDev
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);
export default store;
