import lifeStoryTypes from '../types/lifeStoryStates';
import commonTypes from '../types/common';

export const initialState = {
  timeline: {},
  activeStoryId: null,
  load: false,
};

export const lifeStory = (state = initialState, action) => {
  let storyId = null;
  let updatedTimeline = { ...state.timeline };

  switch (action.type) {
    case lifeStoryTypes.GET_TIMELINE:
      return { ...state, load: true };
    case lifeStoryTypes.GET_TIMELINE_SUCCESS:
      return { ...state, timeline: action.payload, load: false };
    case lifeStoryTypes.GET_TIMELINE_FAIL:
      return { ...state, load: false };

    case lifeStoryTypes.CREATE_STORY:
      return { ...state, load: true };
    case lifeStoryTypes.CREATE_STORY_SUCCESS:
      storyId = action.payload._id;
      updatedTimeline[storyId] = action.payload;
      return { ...state, timeline: updatedTimeline, load: false };
    case lifeStoryTypes.CREATE_STORY_FAIL:
      return { ...state, load: false };

    case lifeStoryTypes.UPDATE_STORY:
      return { ...state, load: true };
    case lifeStoryTypes.UPDATE_STORY_SUCCESS:
      storyId = action.payload._id;
      updatedTimeline[storyId] = action.payload;
      return { ...state, timeline: updatedTimeline, load: false };
    case lifeStoryTypes.UPDATE_STORY_FAIL:
      return { ...state, load: false };

    case lifeStoryTypes.DELETE_STORY:
      return { ...state, load: true };
    case lifeStoryTypes.DELETE_STORY_SUCCESS:
      storyId = action.payload;
      delete updatedTimeline[storyId];
      return { ...state, timeline: updatedTimeline, load: false };
    case lifeStoryTypes.DELETE_STORY_FAIL:
      return { ...state, load: false };

    case lifeStoryTypes.SET_ACTIVE_STORY:
      return { ...state, activeStoryId: action.payload };
    case lifeStoryTypes.CLEAR_ACTIVE_STORY:
      return { ...state, activeStoryId: null };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
