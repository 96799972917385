module.exports = {
  ACCOUNT_MANAGER_SUCCESS: 'ACCOUNT_MANAGER_SUCCESS',

  GET_ACCOUNT_MANAGER: 'GET_ACCOUNT_MANAGER',
  GET_ACCOUNT_MANAGER_FAIL: 'GET_ACCOUNT_MANAGER_FAIL',

  ADD_ACCOUNT_MANAGER: 'ADD_ACCOUNT_MANAGER',
  ADD_ACCOUNT_MANAGER_FAIL: 'ADD_ACCOUNT_MANAGER_FAIL',

  UPDATE_ACCOUNT_MANAGER: 'UPDATE_ACCOUNT_MANAGER',
  UPDATE_ACCOUNT_MANAGER_FAIL: 'UPDATE_ACCOUNT_MANAGER_FAIL',

  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  ENABLE_OTHER_ACCOUNT_EDIT: 'ENABLE_OTHER_ACCOUNT_EDIT',
  DISABLE_OTHER_ACCOUNT_EDIT: 'DISABLE_OTHER_ACCOUNT_EDIT',
};
