import beneficiariesTypes from '../../types/createWill/beneficiaries';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  beneficiaryInformation: [],
};

export const beneficiaries = (state = initialState, action) => {
  switch (action.type) {
      case beneficiariesTypes.GET_BENEFICIARIES_PAGE_SUCCESS:
          return {
              ...state,
              beneficiaryInformation: action.payload,
          };
      case beneficiariesTypes.GET_BENEFICIARIES_PAGE_FAIL:
          return { ...state, error: action.payload };

      case beneficiariesTypes.UPDATE_BENEFICIARIES_PAGE_SUCCESS:
          return {
              ...state,
              beneficiaryInformation: action.payload,
          };
      case beneficiariesTypes.UPDATE_BENEFICIARIES_PAGE_FAIL:
          return { ...state, error: action.payload };

      case beneficiariesTypes.RESET_WILL_BENIFICIARIES_PAGE:
          return { ...initialState };

      case commonTypes.CLEAR_STORAGE:
          return { ...initialState };

      default:
          return state;
  }
};
