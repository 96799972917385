import validator from 'validator';
import jwt from 'jsonwebtoken';

export function authUser() {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }
  return true;
}

export function decodeToken(authorization) {
  try {
    // let authorizationString = authorization.substr(7);
    return [null, jwt.decode(authorization)];
  } catch (error) {
    console.log(error);
    return [error];
  }
}

export const isValidMail = (value, options = {}) => {
  const DEFAULT_CHARACTER_LENGTH = 100;

  let isValid = true;
  let message = '';
  let characterLength = DEFAULT_CHARACTER_LENGTH;
  let isRequired = false;
  let requiredErrorMsg = 'Please enter email';

  if (options.characterLength) {
    characterLength = options.characterLength;
  }
  if (options.isRequired) {
    isRequired = options.isRequired;
  }
  if (options.requiredErrorMsg) {
    requiredErrorMsg = options.requiredErrorMsg;
  }

  if (value) {
    if (!isMail(value)) {
      isValid = false;
      message = 'Please enter a valid email address';
    } else if (value.length > characterLength) {
      isValid = false;
      message = `Character count should be lower than ${characterLength}`;
    }
  } else if (isRequired) {
    isValid = false;
    message = requiredErrorMsg;
  }

  return [isValid, message];
};

export const isMail = (value) => {
  // return validator.isEmail(value, { allow_utf8_local_part: false})
  return validator.isEmail(value);
  // return (/^([a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~])+(([\.]?)|[a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~]+)([a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~]+)@\w+([\.-]?\w+)*(\.\w{2,4})+$/).test(value)
};

export const isString = (value) => {
  return !/[^a-z]/i.test(value);
};

export const isStringWithSpace = (value) => {
  return /^[a-zA-Z ]*$/.test(value);
};

export const passwordValidator = (value) => {
  return /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>\/\?\"\']){1})(?=.*\d)((?=.*[A-Z]){1}).*$/.test(
    value
  );
};

export const isValidUrl = (value) => {
  return (
    validator.isURL(value) &&
    (value.includes('http://') || value.includes('https://'))
  );
};

export const isNumeric = (value) => {
  return validator.isNumeric(value);
};

export const isAlphanumeric = (value) => {
  return validator.isAlphanumeric(value);
};

export const isAlphanumericWithSpace = (value) => {
  return /^[a-zA-Z0-9@#&* ]*$/.test(value);
};

export const isAlphanumericWithSpecialCharacters = (value) => {
  return /^[a-zA-Z0-9@#&*]*$/.test(value);
};
