import assetsTypes from '../../types/createWill/assets';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  assetsInformation: [],
};

export const assets = (state = initialState, action) => {
  switch (action.type) {
    case assetsTypes.GET_ASSETS_PAGE_SUCCESS:
      return {
        ...state,
        assetsInformation: action.payload,
      };
    case assetsTypes.GET_ASSETS_PAGE_FAIL:
      return { ...state, error: action.payload };

    case assetsTypes.UPDATE_ASSETS_PAGE_SUCCESS:
      return {
        ...state,
        assetsInformation: action.payload,
      };
    case assetsTypes.UPDATE_ASSETS_PAGE_FAIL:
      return { ...state, error: action.payload };

    case assetsTypes.RESET_WILL_ASSETS_PAGE:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
