import probateWillCommonTypes from '../../types/probateWill/common';
import probateWillLifeStoryTypes from '../../types/probateWill/lifeStory';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  lifeStories: [],
};

export const lifeStory = (state = initialState, action) => {
  switch (action.type) {
    case probateWillLifeStoryTypes.PROBATE_GET_LIFESTORY_SUCCESS:
      return {
        ...state,
        lifeStories: action.payload,
      };
    case probateWillLifeStoryTypes.PROBATE_GET_LIFESTORY_FAIL:
      return { ...state, error: action.payload, lifeStories: [] };

    case probateWillCommonTypes.RESET_PROBATE_WILL:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
