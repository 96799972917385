import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';

import { CONFIRM_ALERT } from '../../../store/types/common';
import { Modal, ModalBody } from '../../model';

const ConfirmAlert = (prop) => {
  const {
    show = false,
    title = 'Confirm',
    type = CONFIRM_ALERT.WARNING,
    text = 'Are you sure?',
    showCancelButton = true,
    onConfirm,
    onCancel = null,
    confirmButtonText = 'Ok',
    cancelButtonText = 'Cancel',
  } = prop;

  const icon =
    type === CONFIRM_ALERT.WARNING ? (
      <WarningOutlined />
    ) : (
      <InfoCircleOutlined />
    );

  return (
    <Modal
      className="dialog modal-sm"
      customModelClass="modal-center"
      isOpen={show}
    >
      <ModalBody className="content confirmation-mod">
        {icon}
        <h4 className="mt-14 mb-14">{title}</h4>
        <p>{text}</p>
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={onCancel}
          >
            {cancelButtonText}
          </button>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={onConfirm}
        >
          {confirmButtonText}
        </button>
      </ModalBody>
    </Modal>
  );
};

ConfirmAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export { ConfirmAlert };
