import React from 'react';

import LoadingImage from '../../../assets/images/loading-gif-orange-5.gif';

const Loading = (props) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={({ color: 'red' }, { height: 'calc(100vh - 150px)' })}
    >
      <img src={LoadingImage} style={({ width: '75px' }, { height: '75px' })} />
    </div>
  );
};
export { Loading };
