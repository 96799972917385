import * as RESTHelper from './webhelper';
import { sessionLogoutResponseAlert } from '../store/actions/common';

const request = (requestToExecute, dispatch) => {
  return requestToExecute
    .then((res) => {
      if (
        res.data.code &&
        !(res.data.code === 200 || res.data.code === 201) &&
        !res.data.success
      ) {
        handleRequestFailure({ response: res }, dispatch);
      } else {
        return res;
      }
    })
    .catch((err) => {
      console.log('error', err);
      handleRequestFailure(err, dispatch);
    });
};

const handleRequestFailure = (error, dispatch) => {
  const { response } = error;
  if (response && response.status === 401) {
    console.log('Error code 401');
  } else if (response && response.data.code === 'WILL4014') {
    console.log('Error code WILL4014 == > Will exists for the user');
  } else if (response && response.data.code === 'WILL4006') {
    console.log('Error code WILL4006 == > Data Not Found');
  } else if (
    response &&
    (response.data.code === 'WILL4003' || response.data.code === 'WILL4004')
  ) {
    console.log('Error == > Invalid credentials');
    throw response.data.message;
  } else if (response && response.data.code === 'WILL4010') {
    console.log('Error == > Invalid verification code');
    throw 'Invalid verification code.';
  } else if (response && response.data.code === 'WILL4012') {
    console.log('Error == > Law firm registration number is already exits');
    throw response.data.message;
  } else if (response && response.status === 403) {
    sessionLogoutResponseAlert(dispatch);
    //throw response.data.Message
  } else if (response && response.data) {
    if (response.data.message) {
      throw response.data.message;
    } else {
      throw 'Something went wrong! Please try again later.';
    }
  } else {
    console.log('error', typeof error);
    if (error.message) {
      throw error.message;
    } else if (typeof error === 'string') {
      throw error;
    } else {
      throw 'Something went wrong! Please try again later.';
    }
  }
};

export const get = (url, config = {}, dispatch) =>
  request(RESTHelper.get(url, config), dispatch);

export const post = (url, body, config, dispatch) =>
  request(RESTHelper.post(url, body, config), dispatch);

export const put = (url, body, config, dispatch) =>
  request(RESTHelper.put(url, body, config), dispatch);

export const del = (url, body, config, dispatch) =>
  request(RESTHelper.del(url, body, config), dispatch);

export const getNewAPIReqCancelSource = () => RESTHelper.APIReqCancelSource();
