module.exports = {
  GET_MY_WILLS_SUCCESS: 'GET_MY_WILLS_SUCCESS',
  GET_MY_WILLS_FAIL: 'GET_MY_WILLS_FAIL',

  MANAGE_WILL_SEND_WILL_DELETE_REQUEST_SUCCESS: 'MANAGE_WILL_SEND_WILL_DELETE_REQUEST_SUCCESS',
  MANAGE_WILL_SEND_WILL_DELETE_REQUEST_FAIL: 'MANAGE_WILL_SEND_WILL_DELETE_REQUEST_FAIL',

  MANAGE_WILL_SOFT_DELETE_WILL_SUCCESS: 'MANAGE_WILL_SOFT_DELETE_WILL_SUCCESS',
  MANAGE_WILL_SOFT_DELETE_WILL_FAIL: 'MANAGE_WILL_SOFT_DELETE_WILL_FAIL',

  MANAGE_WILL_PARTNER_JOINT_REQUEST_UPDATE_SUCCESS: 'MANAGE_WILL_PARTNER_JOINT_REQUEST_UPDATE_SUCCESS',
  MANAGE_WILL_PARTNER_JOINT_REQUEST_UPDATE_FAIL: 'MANAGE_WILL_PARTNER_JOINT_REQUEST_UPDATE_FAIL',

  MANAGE_WILL_MAKE_PRIMARY_WILL_SUCCESS: 'MANAGE_WILL_MAKE_PRIMARY_WILL_SUCCESS',
  MANAGE_WILL_MAKE_PRIMARY_WILL_FAIL: 'MANAGE_WILL_MAKE_PRIMARY_WILL_FAIL',

  MANAGE_WILL_OPEN_DELETE_WILL_MODAL: 'MANAGE_WILL_OPEN_DELETE_WILL_MODAL',
  MANAGE_WILL_CLOSE_DELETE_WILL_MODAL: 'MANAGE_WILL_CLOSE_DELETE_WILL_MODAL',

  MANAGE_WILL_OPEN_PREVIEW_WILL_MODAL: 'MANAGE_WILL_OPEN_PREVIEW_WILL_MODAL',
  MANAGE_WILL_CLOSE_PREVIEW_WILL_MODAL: 'MANAGE_WILL_CLOSE_PREVIEW_WILL_MODAL',

  SHOW_RECENTLY_JOINED_WILL_ALERT: 'SHOW_RECENTLY_JOINED_WILL_ALERT',

  MANAGE_WILL_WILL_ACTIVATION_MODAL_STATUS: 'MANAGE_WILL_WILL_ACTIVATION_MODAL_STATUS',
  
  MANAGE_WILL_WILL_DEACTIVATION_MODAL_STATUS: 'MANAGE_WILL_WILL_DEACTIVATION_MODAL_STATUS'
  

};
