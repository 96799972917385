import React, { useEffect } from 'react';
import classNames from 'classnames';

const Modal = ({
  isOpen = false,
  children,
  className,
  customModelClass,
  zIndex = 999999,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  let modalClose = classNames(
    'modal',
    isOpen ? 'modal-open' : 'modal-close',
    customModelClass
  );
  let modlaDialogStyles = classNames('modal-dialog', className);

  return (
    <div className={modalClose} style={{ zIndex: zIndex }}>
      <div className={modlaDialogStyles}>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export { Modal };
