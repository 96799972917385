module.exports = {
  USER_SIGNUP: 'USER_SIGNUP',
  USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
  USER_SIGNUP_FAIL: 'USER_SIGNUP_FAIL',
  USER_SIGNUP_STEP: 'USER_SIGNUP_STEP',

  USER_SIGNIN: 'USER_SIGNIN',
  USER_SIGNIN_SUCCESS: 'USER_SIGNIN_SUCCESS',
  USER_SIGNIN_FAIL: 'USER_SIGNIN_FAIL',

  USER_GET_SIGNED_COOKIES: 'USER_GET_SIGNED_COOKIES',
  USER_GET_SIGNED_COOKIES_SUCCESS: 'USER_GET_SIGNED_COOKIES_SUCCESS',
  USER_GET_SIGNED_COOKIES_FAIL: 'USER_GET_SIGNED_COOKIES_FAIL',

  USER_UPLOAD_PROFILE_PIC: 'USER_UPLOAD_PROFILE_PIC',
  USER_UPLOAD_PROFILE_PIC_SUCCESS: 'USER_UPLOAD_PROFILE_PIC_SUCCESS',
  USER_UPLOAD_PROFILE_PIC_FAIL: 'USER_UPLOAD_PROFILE_PIC_FAIL',

  USER_VERIFY: 'USER_VERIFY',
  USER_VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
  USER_VERIFY_FAIL: 'USER_VERIFY_FAIL',

  RESEND_VERIFY_EMAIL: 'RESEND_VERIFY_EMAIL',
  RESEND_VERIFY_EMAIL_SUCCESS: 'RESEND_VERIFY_EMAIL_SUCCESS',
  RESEND_VERIFY_EMAIL_FAIL: 'RESEND_VERIFY_EMAIL_FAIL',

  SEND_FORGOT_PASSWORD_CODE: 'SEND_FORGOT_PASSWORD_CODE',
  SEND_FORGOT_PASSWORD_CODE_SUCCESS: 'SEND_FORGOT_PASSWORD_CODE_SUCCESS',
  SEND_FORGOT_PASSWORD_CODE_FAIL: 'SEND_FORGOT_PASSWORD_CODE_FAIL',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',

  UPDATE_PROFILE_INFO: 'UPDATE_PROFILE_INFO',

  USER_LOGOUT: 'USER_LOGOUT',

  ADD_LAW_FIRM: 'ADD_LAW_FIRM',
  ADD_LAW_FIRM_SUCCESS: 'ADD_LAW_FIRM_SUCCESS',
  ADD_LAW_FIRM_FAIL: 'ADD_LAW_FIRM_FAIL',

  GET_LAW_FIRMS: 'GET_LAW_FIRMS',
  GET_LAW_FIRMS_SUCCESS: 'GET_LAW_FIRMS_SUCCESS',
  GET_LAW_FIRMS_FAIL: 'GET_LAW_FIRMS_FAIL',

  ADD_LAWYER: 'ADD_LAWYER',
  ADD_LAWYER_SUCCESS: 'ADD_LAWYER_SUCCESS',
  ADD_LAWYER_FAIL: 'ADD_LAWYER_FAIL',

  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAIL: 'GET_COUNTRIES_FAIL',

  INVITE_LAWYERS: 'INVITE_LAWYERS',
  INVITE_LAWYERS_SUCCESS: 'INVITE_LAWYERS_SUCCESS',
  INVITE_LAWYERS_FAIL: 'INVITE_LAWYERS_FAIL',

  OPEN_MODAL_REGISTER_LAW_FIRM: 'OPEN_MODAL_REGISTER_LAW_FIRM',
  CLOSE_MODAL_REGISTER_LAW_FIRM: 'CLOSE_MODAL_REGISTER_LAW_FIRM',
  
  SET_FIREBASE_TOKEN: 'SET_FIREBASE_TOKEN'
};
