import commonTypes from '../types/common';

export const initialState = {
  show: false,
  message: '',
  type: 0,
  //  sessionExpire:false
};

export const common = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.SHOW:
      return {
        ...state,
        show: true,
        message: action.payload.msg,
        type: action.payload.type,
      };
    case commonTypes.HIDE:
      return {
        ...state,
        show: false,
        message: initialState.message,
        type: initialState.type,
      };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    case commonTypes.SESSION_EXPIRE:
      return {
        ...state,
        sessionExpire: true,
      };
    default:
      return state;
  }
};
