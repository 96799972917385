import probateWillCommonTypes from '../../types/probateWill/common';
import probateWillFuneralPlanTypes from '../../types/probateWill/funeralPlan';
import commonTypes from '../../types/common';

export const initialState = {
  error: '',
  funeralPlanData: null,
};

export const funeralPlan = (state = initialState, action) => {
  switch (action.type) {
    case probateWillFuneralPlanTypes.PROBATE_GET_FUNERAL_PLAN_SUCCESS:
      return {
        ...state,
        funeralPlanData: action.payload,
      };
    case probateWillFuneralPlanTypes.PROBATE_GET_FUNERAL_PLAN_FAIL:
      return { ...state, error: action.payload, funeralPlanData: null };

    case probateWillFuneralPlanTypes.PROBATE_UPDATE_FUNERAL_PLAN_INVITATION_MSG_SUCCESS:
      return { ...state };
    case probateWillFuneralPlanTypes.PROBATE_UPDATE_FUNERAL_PLAN_INVITATION_MSG_FAIL:
      return { ...state };

    case probateWillCommonTypes.RESET_PROBATE_WILL:
      return { ...initialState };

    case commonTypes.CLEAR_STORAGE:
      return { ...initialState };

    default:
      return state;
  }
};
