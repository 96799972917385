import React from 'react';

const Col = ({ className, children, style }) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
export { Col };
