import manageWillTypes from '../../types/manageWill/manage';
import commonTypes from '../../types/common';

export const initialState = {
	error: '',
	wills: [],
	isModalOpenDeleteWill: false,
	isModalOpenPreviewWill: false,
	previewWillEnabledIds: [],
	isWillActivationModalOpen: false,
	isWillDeactivateModalOpen: false,
};

export const manage = (state = initialState, action) => {
	switch (action.type) {
		case manageWillTypes.GET_MY_WILLS_SUCCESS:
			return {
				...state,
				wills: action.payload.wills,
			};
		case manageWillTypes.GET_MY_WILLS_FAIL:
			return { ...state, error: action.payload };

		case manageWillTypes.MANAGE_WILL_SEND_WILL_DELETE_REQUEST_SUCCESS:
			return { ...state, wills: action.payload };
		case manageWillTypes.MANAGE_WILL_SEND_WILL_DELETE_REQUEST_FAIL:
			return { ...state, error: action.payload };

		case manageWillTypes.MANAGE_WILL_SOFT_DELETE_WILL_SUCCESS:
			return { ...state };
		case manageWillTypes.MANAGE_WILL_SOFT_DELETE_WILL_FAIL:
			return { ...state, error: action.payload };

		case manageWillTypes.MANAGE_WILL_PARTNER_JOINT_REQUEST_UPDATE_SUCCESS:
			return { ...state, wills: action.payload };
		case manageWillTypes.MANAGE_WILL_PARTNER_JOINT_REQUEST_UPDATE_FAIL:
			return { ...state, error: action.payload };

		case manageWillTypes.MANAGE_WILL_MAKE_PRIMARY_WILL_SUCCESS:
			return { ...state };
		case manageWillTypes.MANAGE_WILL_MAKE_PRIMARY_WILL_FAIL:
			return { ...state, error: action.payload };

		case manageWillTypes.MANAGE_WILL_OPEN_DELETE_WILL_MODAL:
			return { ...state, isModalOpenDeleteWill: true };
		case manageWillTypes.MANAGE_WILL_CLOSE_DELETE_WILL_MODAL:
			return { ...state, isModalOpenDeleteWill: false };

		case manageWillTypes.MANAGE_WILL_OPEN_PREVIEW_WILL_MODAL:
			return { ...state, isModalOpenPreviewWill: true };
		case manageWillTypes.MANAGE_WILL_CLOSE_PREVIEW_WILL_MODAL:
			return { ...state, isModalOpenPreviewWill: false };
		case manageWillTypes.SHOW_RECENTLY_JOINED_WILL_ALERT:
			return { ...state, previewWillEnabledIds: action.payload };

		case manageWillTypes.MANAGE_WILL_WILL_ACTIVATION_MODAL_STATUS:
			return { ...state, isWillActivationModalOpen: action.payload };

		case manageWillTypes.MANAGE_WILL_WILL_DEACTIVATION_MODAL_STATUS:
			return { ...state, isWillDeactivateModalOpen: action.payload };

		case commonTypes.CLEAR_STORAGE:
			return { ...initialState };
		default:
			return state;
	}
};
