import moment from 'moment';

export const addCurrentTimeToDate = (dateObj) => {
  const now = new Date();

  try {
    dateObj.setHours(now.getHours());
    dateObj.setMinutes(now.getMinutes());
    dateObj.setSeconds(now.getSeconds());
  } catch (err) {
    return dateObj;
  }

  return dateObj;
};

export const addYears = (date, years) => {
  try {
    let addedDate = moment(date).add(years, 'year');
    return addedDate.toDate();
  } catch (err) {
    console.error('Unable to add years', err);
    return date;
  }
};

export const subtractYears = (date, years) => {
  try {
    let subtractedDate = moment(date).subtract(years, 'year');
    return subtractedDate.toDate();
  } catch (err) {
    console.error('Unable to subtract years', err);
    return date;
  }
};

export const addDays = (date, days) => {
  try {
    let addedDate = moment(date).add(days, 'day');
    return addedDate.toDate();
  } catch (err) {
    console.error('Unable to add days', err);
    return date;
  }
};

export const subtractDays = (date, days) => {
  try {
    let subtractedDate = moment(date).subtract(days, 'day');
    return subtractedDate.toDate();
  } catch (err) {
    console.error('Unable to subtract days', err);
    return date;
  }
};

export const getFormattedDateString = (date, format = 'MM/DD/YYYY') => {
  try {
    return moment(date).format(format);
  } catch (err) {
    console.error('Unable to format date', err);
    return date;
  }
};

export const getDateCountFromToday = (date) => {
  try {
    const today = moment();
    const givenDate = moment(date);
    return today.diff(givenDate, 'days');
  } catch (err) {
    console.error('Unable to compute date', err);
    return date;
  }
};

export const getYearsCountFromToday = (date) => {
  try {
    const today = moment();
    const givenDate = moment(date);
    return today.diff(givenDate, 'years');
  } catch (err) {
    console.error('Unable to compute date', err);
    return date;
  }
};

export const getFormattedAgoDateString = (date) => {
  try {
    moment.relativeTimeThreshold('s', 10);
    return moment(date).fromNow();
  } catch (err) {
    console.error('Unable to calculate ago date', err);
    return date;
  }
};

export const getUTCDatetime = (datetime) => {
  try {
    return moment(datetime).utc(true);
  } catch (err) {
    console.error('Unable to calculate utc date time', err);
    return datetime;
  }
};
