import React from 'react';
import classNames from 'classnames';

const ModalClose = ({ close, className, disabled = false }) => {
  let title = classNames(className);

  return (
    <button type="button" className={title} onClick={close} disabled={disabled}>
      <span aria-hidden="true">&times;</span>
    </button>
  );
};
export { ModalClose };
