import axios from 'axios';

import * as ApiConnector from '../../libs/apiConnector';
import { ROUTES } from '../../config';
import authTypes from '../types/authStates';
import settingTypes from '../types/settings';
import { RESPONSE_ALERT } from '../types/common';
import { showResponseMessage, uploadFileToS3 } from './common';

const defaultErrorMsg = 'Something went wrong! Please try again later.';

export function submitPersonalInfo(data, showResponseMsg = true) {
  return async function (dispatch) {
    try {
      const response = await ApiConnector.put(
        `${ROUTES.UPDATE_USER_PROFILE}`,
        data,
        { headers: { 'Content-Type': 'application/json' } },
        dispatch
      );
      if (
        response &&
        response.status === 200 &&
        response.data.success === true
      ) {
        localStorage.setItem('token', response.data.data.token);
        dispatch({
          type: settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_SUCCESS,
        });
        dispatch({
          type: authTypes.UPDATE_PROFILE_INFO,
          payload: response.data.data.user,
        });
        if (showResponseMsg) {
          showResponseMessage(
            dispatch,
            RESPONSE_ALERT.SUCCESS,
            'Profile updated.'
          );
       }
        dispatch(updatePersonalInfoEditState(false));
      } else {
        dispatch({
          type: settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_FAIL,
          payload: response.message,
        });
        showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, defaultErrorMsg);
      }
    } catch (exception) {
      dispatch({
        type: settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_FAIL,
        payload: exception,
      });
      showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, defaultErrorMsg);
    }
  };
}
export function uploadProfileImage(uploadURl, data) {
  return async function (dispatch) {
    try {
      if (uploadURl && data.file) {
        dispatch({
          type: settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE,
        });
        const uploadSuccess = await uploadFileToS3(
          uploadURl,
          data.file,
          data.file.type,
          dispatch,
          null,
          null,
          true
        );
        if (uploadSuccess) {
          dispatch({
            type:
              settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_SUCCESS,
          });
          showResponseMessage(
            dispatch,
            RESPONSE_ALERT.SUCCESS,
            'Profile image updated'
          );
          dispatch(updateProfilePicInAccountComp(URL.createObjectURL(data.file)));
        } else {
          dispatch({
            type: settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_FAIL,
          });
          showResponseMessage(
            dispatch,
            RESPONSE_ALERT.ERROR,
            'Failed to upload file'
          );
        }
      }
    } catch (exception) {
      dispatch({
        type: settingTypes.UPDATE_SETTINGS_PAGE_UPLOAD_PROFILE_IMAGE_FAIL,
        payload: exception,
      });
      showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, defaultErrorMsg);
    }
  };
}

export function submitSecurityInfo(data) {
  return async function (dispatch) {
    try {
      const response = await ApiConnector.put(
        `${ROUTES.UPDATE_USER_PROFILE}/update-password`,
        data,
        { headers: { 'Content-Type': 'application/json' } },
        dispatch
      );
      if (
        response &&
        response.status === 200 &&
        response.data.success === true
      ) {
        dispatch({
          type: settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_SUCCESS,
        });
        dispatch({
          type: authTypes.USER_SIGNIN_SUCCESS,
          payload: response.data.data.user,
        });
        // showResponseMessage(
        //   dispatch,
        //   RESPONSE_ALERT.SUCCESS,
        //   'Password updated.'
        // );
        dispatch(updateSecurityInfoEditState(false));
      } else {
        dispatch({
          type: settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_FAIL,
          payload: response.message,
        });
        showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, defaultErrorMsg);
      }
    } catch (exception) {
      dispatch({
        type: settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_FAIL,
        payload: exception,
      });
      showResponseMessage(dispatch, RESPONSE_ALERT.ERROR, exception);
    }
  };
}

export function updatePersonalInfoEditState(state) {
  return async function (dispatch) {
    dispatch({
      type: settingTypes.UPDATE_SETTINGS_PAGE_PERSONAL_INFO_EDITABLE_STATE,
      payload: state,
    });
  };
}

export function updateSecurityInfoEditState(state) {
  return async function (dispatch) {
    dispatch({
      type: settingTypes.UPDATE_SETTINGS_PAGE_SECURITY_INFO_EDITABLE_STATE,
      payload: state,
    });
  };
}

export const updateProfilePicInAccountComp = image => async dispatch => {
  dispatch({
    type: settingTypes.UPDATE_NEWLY_ADDED_PROFILE_PIC_IN_ACCOUNT,
    payload: image,
  });
}

