import axios from 'axios';

const axiosInstance = axios.create();

const avoidToken = (config = {}) => {
  // console.dir("avoidToken")
  return config.hasOwnProperty('avoidToken') && config.avoidToken
    ? true
    : false;
};

const requestHandler = (request) => {
  // console.dir("requestHandler")
  if (!avoidToken(request)) {
    // console.log("Attached token")
    // console.log(localStorage.getItem("token"))
    request.headers['Authorization'] =
      'Bearer ' + localStorage.getItem('token');
  }

  // console.log("No attached token")
  return request;
};

axiosInstance.interceptors.request.use((request) => requestHandler(request));

const successHandler = (response) => {
  // console.dir("successHandler")
  return response;
};

const errorHandler = (error) => {
  // if (error.response) {
  //     // The request was made and the server responded with a status code
  //     // that falls out of the range of 2xx
  //     console.log(error.response.data);
  //     console.log(error.response.status);
  //     console.log(error.response.headers);
  // } else if (error.request) {
  //     // The request was made but no response was received
  //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //     // http.ClientRequest in node.js
  //     console.log(error.request);
  // } else {
  //     // Something happened in setting up the request that triggered an Error
  //     console.log('Error', error.message);
  // }
  // console.log(error.config);

  return Promise.reject({ ...error });
};

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export const get = (url, config) => axiosInstance.get(url, config);

export const post = (url, body, config) =>
  axiosInstance.post(url, body, config);

export const put = (url, body, config) => axiosInstance.put(url, body, config);

export const del = (url, body, config) => axiosInstance.delete(url, config);

export const APIReqCancelSource = () => axios.CancelToken.source();
