import React from 'react';

const Margin = ({
  children,
  margin,
  horizontal,
  vertical,
  containerSize,
  customClass,
}) => {
  let marginType = null;

  if (horizontal) {
    marginType = { margin: `0px  ${margin + 'px'}` };
  } else if (vertical) {
    marginType = { margin: `${margin + 'px'}   0px ` };
  }

  return (
    <div className={customClass} style={marginType}>
      {children}
    </div>
  );
};
export { Margin };
